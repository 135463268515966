<template>
  <div>
    <template>
      <el-row type="flex" justify="center" class="mb-3 mt-3">
        <el-col :span="24" class="back_action_panel">
          <router-link :to="{ name: 'Incidents' }">
            <el-button class="pt-0" type="text" icon="el-icon-back" :disabled="loading">Назад
            </el-button>
          </router-link>
          <el-button v-if="userHasRole('IncidentsEdit')" class="back_action_panel--first_element"
                     @click="saveIncident()" :disabled="loading" type="text">
            <SaveIcon/>
            Сохранить
          </el-button>
          <el-button v-if="userHasRole('IncidentsEdit') && id" class="pt-0" type="text"
                     :disabled="loading"
                     icon="el-icon-delete" @click="handleDelete()">Удалить
          </el-button>
          <el-button v-if="userHasRole('IncidentsEdit')" class="pt-0" type="text"
                     :disabled="loading"
                     icon="el-icon-circle-close" @click="handleCancel()">Отменить
          </el-button>
        </el-col>
      </el-row>
      <el-tabs type="border-card" v-model="activeTabName">
        <el-tab-pane label="Основное" name="main">
          <el-form :rules="rules" v-loading="loading" ref="incidentForm" :model="incident"
                   label-width="12.5rem">
            <el-row :gutter="20" class="mt-0">
              <el-col :span="12">
                <el-row :gutter="15" class="mt-1">
                  <el-col :span="9">
                    <el-form-item label="Номер аварии:" prop="number">
                      <el-input size="small" id="incident_number" disabled
                          v-model="incident.number"></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- <el-col :span="15">
                       <el-date-picker
                        style="width:100%;"
                        size="small"
                        :disabled="loading || !userHasRole('IncidentsEdit')"
                        v-model="periodIncidentTimeRange"
                        type="datetimerange"
                        range-separator="-"
                        placeholder="дд.мм.гггг чч:мм"
                        format="dd.MM.yyyy HH:mm"
                        start-placeholder="с"
                        end-placeholder="по">
                      </el-date-picker>        
                  </el-col> -->                  
                  <el-col :span="15"  >
                    <el-form-item label="Период аварии:" prop="incidentTime">
                      <el-date-picker
                        class="date-editor-padding-off"
                        size="small"
                        v-model="incident.incidentTime"
                        v-mask="'##.##.#### ##:##'"
                        :disabled="loading || !userHasRole('IncidentsEdit')"
                        type="datetime"
                        placeholder="дд.мм.гггг чч:мм"
                        format="dd.MM.yyyy HH:mm"
                        style="width:47%;"
                        @change="changeIncidentTime"
                        :picker-options="datePickerOptions">
                      </el-date-picker>    
                      <span class="text-center" style="display: inline-block; width:5%;" >-</span>
                      <el-date-picker
                        class="date-editor-padding-off"
                        :class="{'my-error-end-time': !this.incident.incidentTime 
                                    || this.incident.incidentTime === ' ' 
                                    || moment(this.incident.incidentTime) > moment() 
                                    || moment(this.incident.incidentTime) < moment(new Date(2012, 0).toISOString()) }"
                        size="small"
                        v-model="incident.incidentEndTime"
                        v-mask="'##.##.#### ##:##'"
                        :disabled="loading || !userHasRole('IncidentsEdit')"
                        type="datetime"
                        placeholder="дд.мм.гггг чч:мм"
                        format="dd.MM.yyyy HH:mm"
                        style="width:47%;"
                        :picker-options="datePickerOptions">
                      </el-date-picker>    
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="15" class="mt-0">
                  <el-col :span="9">
                    <el-form-item label="Требует корректировки:" prop="needCorrect">
                      <el-checkbox :disabled="loading || !userHasRole('IncidentsEdit')"
                                   v-model="incident.needCorrect"></el-checkbox>
                    </el-form-item>
                  </el-col>
                  <el-col :span="15">
                    <el-form-item label="Проверка БММС:" prop="bmmnCheckTypeId">
                      <el-select v-model="incident.bmmnCheckTypeId" size="small"
                                 :disabled="loading || !userHasRole('IncidentsEdit')"
                                 placeholder="Выберите">
                        <el-option v-for="it in bmmnCheckTypes"
                                   :value="it.id"
                                   :label="it.name"
                                   :key="it.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="24">
                    <el-form-item label="Описание аварии:" prop="description">
                      <el-input id="incident_description" type="textarea"  size="small"
                                :disabled="loading || !userHasRole('IncidentsEdit')" :rows="15"
                                placeholder="" v-model="incident.description"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10" class="mt-0" v-if="showEventMapMsg">
                  <el-col :span="24">
                    <el-card>
                      <!-- <el-row class="my-label-14 mt-0">
                        <el-col :span="6" style="padding: 12px 0">Сопоставление событий</el-col>
                      </el-row> -->
                      <el-row class="my-label-14 mt-0">

                        <el-col :span="10" style="padding: 12px 0">Найдено события для
                          сопоставления:
                        </el-col>
                        <el-col :span="3">
                          <el-button type="text" @click="handleToMap">{{ eventMap.length }}
                          </el-button>
                        </el-col>
                        <el-col :span="3">
                          <el-button type="text" icon="el-icon-refresh"
                                     @click="loadMap"></el-button>
                        </el-col>
                      </el-row>
                    </el-card>
                  </el-col>
                </el-row>
              </el-col>

              <el-col :span="12">
                <el-row :gutter="10" class="mt-1">
                  <el-col :span="12">
                    <span class="mr-3">Первичное оборудование/ЛЭП:</span>
                    <el-button v-if="userHasRole('IncidentsEdit')" :disabled="loading"
                               @click="handlePrimaryEquipmentModalOpen" icon="el-icon-circle-check"
                               size="small">Выбрать
                    </el-button>
                  </el-col>
                  <el-col :span="12">
                    <span>
                      Устройства РЗА:
                    </span>
                    <el-tooltip placement="bottom" effect="light">
                      <!-- <div slot="content">Указанный раздел предназначен исключительно
                        <br/> для заведения в аварию информации по сработавшему
                        <br/> общестанционному/общеподстанционному оборудованию,
                        <br/> а также для случаев, когда срабатывание устройства РЗА
                        <br/> не связано с повреждением и/или аварийным
                        <br/> отключением (неотключением)
                        <br/> ЛЭП/первичного оборудования энергообъекта
                      </div> -->
                      <div slot="content">Раздел предназначен для учета срабатываний
                        <br/> общестанционного/общеподстанционного оборудования, а также
                        <br/> для срабатываний устройств РЗА не связанных
                        <br/> с повреждением и/или аварийным отключением (неотключением)
                        <br/> ЛЭП/первичного оборудования энергообъекта
                      </div>
                      <i class="el-icon-info tooltip mr-3"/>
                    </el-tooltip>
                    <el-button v-if="userHasRole('IncidentsEdit')" :disabled="loading"
                               @click="handleSecondEquipmentPanelModalOpen"
                               icon="el-icon-circle-check" size="small">Выбрать
                    </el-button>
                  </el-col>
                </el-row>
                <el-form-item prop="primaryEq" label-width="0">
                  <el-table :data="tableDate" v-loading="loading"
                            style="width: 100%; ">
                    <el-table-column
                      width="50">
                      <template slot-scope="scope">
                        <div class="tdWraper">
                          <span>
                              <i v-if="scope.row.secondEP"><ShieldIcon/></i>
                              <i v-else><SettingIcon/></i>
                          </span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="name"
                      label="Наименование">
                    </el-table-column>
                    <el-table-column
                      prop="energyObjectsString"
                      label="Энергообъект">
                    </el-table-column>
                  </el-table>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <select-primary-equipment-modal
            v-if="primaryEquipmentModalVisible"
            v-model="primaryEquipmentModalVisible"
            :incident-id="this.id"
            :items="selectedPrimaryEquipments"
            @change="changePe($event);"
          />

          <select-second-equipment-panel-modal
            v-if="secondEquipmentPanelModalVisible"
            v-model="secondEquipmentPanelModalVisible"
            :incident-id="this.id"
            :key="modalKey"
            :items="selectedSecondEquipmentPanels"
            @change="changeSep($event)"
          />

        </el-tab-pane>

        <el-tab-pane label="События" :disabled="loading || !this.incident.id" name="events">
          <el-row :gutter="20" class="mt-0">
            <el-col :span="2">
              <router-link :to="{ name: 'EventAdd', params: {
                incidentId: this.id,
                incidentNumber: this.incident.number,
                incidentTime: this.incident.incidentTime,
                userData: false
              } }">
                <el-button v-if="userHasRole('IncidentsEdit')" type="text"
                           icon="el-icon-circle-plus-outline">Добавить
                </el-button>
              </router-link>
            </el-col>
            <el-col :span="7">
              <el-col :span="13" class="mt-2 pr-0">
                <span>Отображать только удаленные</span>
              </el-col>
              <el-col :span="8" class="mt-2 pl-0">
                <el-switch size="small" v-model="eventsShowDeleted"></el-switch>
              </el-col>
            </el-col>
          </el-row>
          <el-table v-loading="loading"
                    :data="events"
                    :default-sort="{prop: 'name', order: 'ascending'}"
                    style="width: 100%">

            <el-table-column fixed="left" width="42px">
              <template slot-scope="scope">
                <router-link :to="{ name: 'IncidentEventItem', params: { id: scope.row.id } }">
                  <el-button id="event--button-view" type="text" icon="el-icon-search"></el-button>
                </router-link>
              </template>
            </el-table-column>
            <el-table-column prop="number" label="Номер события" width="150px"/>
            <el-table-column prop="eventTime" label="Дата и время события"
                             :formatter="dateTimeRenderer" width="150px"/>
            <el-table-column prop="energySystemName" label="Энергосистема" width="200px"/>
            <el-table-column prop="energyObjectsName" label="Энергообъект" min-width="1"/>
            <el-table-column label="Оборудование/ЛЭП события" min-width="2">
              <template slot-scope="scope">
                {{ eventEquipment(scope.row) }}
              </template>
            </el-table-column>
            <el-table-column prop="baseVoltageName" label="Напряжение" width="150"/>
            <el-table-column width="42px">
              <template v-if="userHasRole('IncidentsEdit')" slot-scope="scope">
                <el-button v-if="!scope.row.deleteTime" type="text" icon="el-icon-delete"
                           @click="handleEventDelete(scope.row)"></el-button>
                <el-button v-if="scope.row.deleteTime" type="text" icon="el-icon-refresh-left"
                           @click="handleEventRestore(scope.row)"></el-button>
              </template>
            </el-table-column>
          </el-table>

        </el-tab-pane>

        <el-tab-pane label="Журнал" :disabled="this.incident.id == null" name="journal">
          <incident-journal
            :incident-id="this.incident.id"
          />
        </el-tab-pane>
      </el-tabs>
    </template>
    <event-mapping-modal :my-store="this.myModalStore"></event-mapping-modal>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import { mapGetters } from 'vuex';
import incidentsApi from '@/api/eventsAndIncidents/incidents';
import eventsApi from '@/api/eventsAndIncidents/events/';
import SaveIcon from '@/assets/icons/save.svg';
import ShieldIcon from '@/assets/icons/shield.svg';
import SettingIcon from '@/assets/icons/settings.svg';
import formatters from '@/mixins/formatters.js';
import dateHelpers from '@/mixins/dateHelpers';
import tableResize from '@/mixins/tableResize.js';
import EventMappingModalStore from '@/views/EventsAndIncidents/EventMapping/EventMappingModalStore';
import EventMappingModal from '@/views/EventsAndIncidents/EventMapping/EventMappingModal.vue';
import SelectSecondEquipmentPanelModal
  from './Modals/SecondEquipmentPanelModal/SelectSecondEquipmentPanelModal';
import SelectPrimaryEquipmentModal
  from './Modals/PrimaryEquipmentModal/SelectPrimaryEquipmentModal';
import IncidentJournal from './IncidentJournal/Journal';
// import iconsvg  from '@/components/Ui/InfiniteTree/icons.js';

const diff = require('recursive-diff');

export default {
  name: 'Incident',
  props: ['id'],
  mixins: [formatters, dateHelpers, tableResize],
  components: {
    EventMappingModal,
    SaveIcon,
    SelectSecondEquipmentPanelModal,
    SelectPrimaryEquipmentModal,
    IncidentJournal,
    ShieldIcon,
    SettingIcon
  },
  data() {
    const primaryEquipmentValidation = (rule, value, callback) => {
      if (this.primaryEquipmentsTableData.length < 1 && this.secondEquipmentPanelsTableData.length < 1) {
        return callback(new Error('Необходимо выбрать хотя бы одно первичное оборудование или устройство РЗА'));
      } else {
        return callback();
      }
    };
    const incidentTimeValidation = (rule, value, callback) => {
      const minDate = new Date(2012, 0).toISOString();
      const currentDate = new Date().toISOString();
      if (value !== ' ' && moment(value) < moment(minDate)) {
        return callback(new Error('Дата начала не должна быть раньше 01.01.2012'));
      } else if (moment(value) > moment(currentDate)) {
        return callback(new Error('Дата начала не должна быть позже текущей'));
      } else if (!this.incident.incidentTime || this.incident.incidentTime === ' ') {
        return callback(new Error('Поле обязательно для заполнения'));
      } else {
        if (this.incident.incidentEndTime == null) {
          this.incident.incidentEndTime = this.incident.incidentTime;
        }
        if (moment(value) > this.moment(this.incident.incidentEndTime)) {
          return callback(new Error(`Дата окончания не должна быть раньше ${moment(this.incident.incidentTime).format('DD.MM.YYYY HH:mm')}`));
        } else if (this.incident.incidentEndTime !== ' ' && moment(this.incident.incidentEndTime) < moment(minDate)) {
          return callback(new Error('Дата окончания не должна быть раньше 01.01.2012'));
        } else if (moment(this.incident.incidentEndTime) > moment(currentDate)) {
          return callback(new Error('Дата окончания не должна быть позже текущей'));
        } else {
          return callback();
        }
      }
    };

    return {
      eventMap: [],
      modalKey: 0,
      checked: false,
      loading: false,
      primaryEquipmentModalVisible: false,
      primaryEquipments: [],
      primaryEquipmentsTableData: [],
      secondEquipmentPanelModalVisible: false,
      secondEquipmentPanelsTableData: [],
      secondEquipmentPanels: [],
      incident: {
        incidentTime: null,
        incidentEndTime: null,
        needCorrect: true,
        description: '',
        number: '',
        bmmnCheckTypeId: 0
      },
      selectedPrimaryEquipments: [],
      selectedSecondEquipmentPanels: [],
      events: [],
      eventsShowDeleted: false,
      // datePickerOptions: {
      //   disabledDate(date) {
      //     const dateNow = new Date();
      //     return date >= new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate());
      //   },
      // },
      rules: {
        incidentTime: [
          {
            validator: incidentTimeValidation,
            trigger: 'blur',
            required: true
          }
        ],
        primaryEq: [
          {
            validator: primaryEquipmentValidation,
            trigger: 'blur',
            required: true
          }
        ],
      },
      activeTabName: 'main'
    };
  },
  computed: {
    ...mapGetters('identity', ['userHasRole', 'userHasRoleGroup']),
    ...mapGetters('dictionaries', ['bmmnCheckTypes', 'estimations', 'staffCategories']),
    showEventMapMsg() {
      return this.eventMap && this.eventMap.length > 0;
    },
    // periodIncidentTimeRange: {
    //   get() {
    //     return [this.dateGetter(this.incident.incidentTime), this.dateGetter(this.incident.incidentEndTime)];
    //   },
    //   set(val) {
    //     this.incident.incidentTime = val ? this.dateSetter(val[0]) : ' ';
    //     this.incident.incidentEndTime = val ? this.dateSetter(val[1]) : ' ';
    //     if (this.showEventsMatch && val && val !== ' ') {
    //       this.loadMap();
    //     }
    //   },
    // },
    showEventsMatch() {
      return this.userHasRole('EventsMatchExternal');
    },
    tableDate() {
      const arrPE = [...this.primaryEquipmentsTableData];
      this.secondEquipmentPanelsTableData.forEach((element) => {
        arrPE.push({
          name: element.name,
          energyObjectsString: element.energyObjectName,
          secondEP: true
        });
      });
      return arrPE;
    }
  },
  watch: {
    eventsShowDeleted() {
      this.getEvents();
    },
    selectedPrimaryEquipments() {
      if (this.showEventsMatch) {
        this.loadMap();
      }
    },
    selectedSecondEquipmentPanels() {
      if (this.showEventsMatch) {
        this.loadMap();
      }
    },
    estimations: {
      handler(est) {
        debugger;
        this.myModalStore.setEstimations(est);
      },
      deep: true
    },
    staffCategories: {
      handler(staff) {
        debugger;
        this.myModalStore.setStaffCategories(staff);
      },
      deep: true
    }
  },
  async created() {
    this.myModalStore = new EventMappingModalStore(this.userHasRoleGroup('ExternalUser'), { toUserDate: this.toUserDate, toServerUtcDate: this.toServerUtcDate });
    if (this.id) {
      this.loading = true;
      await this.getData(this.id)
        .then(() => {
          // eslint-disable-next-line no-unneeded-ternary
          this.eventsShowDeleted = this.incident.deleteTime ? true : false;
          this.getEvents();
          this.loading = false;
        });
    } else {
      // this.incident.incidentTime = this.dateSetter(moment.utc()
      //   .format());
      // this.incident.incidentEndTime = this.dateSetter(moment.utc()
      //   .format());
    }
    if (this.$route.query.tab === 'events') {
      this.activeTabName = this.$route.query.tab;
    }
  },
  methods: {
    changeIncidentTime() {
      if (this.showEventsMatch) {
        this.loadMap();
      }
    },
    async handleToMap() {
      if (this.eventMap && this.eventMap.length > 0) {
        this.myModalStore.state.dialogOpen = true;
      }
    },
    async loadMap() {
      if (!this.id && (this.selectedPrimaryEquipments.length > 0 || this.selectedSecondEquipmentPanels.length > 0) && this.incident && this.incident.incidentTime && this.incident.incidentTime !== ' ') {
        try {
          const primaryEquipmentIds = this.selectedPrimaryEquipments.filter((el) => el.primaryEquipmentId)
            .map((el) => el.primaryEquipmentId);
          const secondEquipmentPanelIds = this.selectedSecondEquipmentPanels.filter((el) => el.id)
            .map((el) => el.id);

      //    const date = this.toServerUtcDate(this.incident.incidentTime);
          const date = this.incident.incidentTime;
          this.eventMap = await this.myModalStore.openIntByIncidentAsync(primaryEquipmentIds, date, secondEquipmentPanelIds);
        } catch (e) {
          this.$message.error(e.message);
        }
      }
    },

    eventEquipment(row) {
      if (row.primaryEquipmentName && !row.secondEquipmentPanelName) {
        return row.primaryEquipmentName;
      }
      if (!row.primaryEquipmentName && row.secondEquipmentPanelName) {
        return row.secondEquipmentPanelName;
      }
      if (row.primaryEquipmentName && row.secondEquipmentPanelName) {
        return `${row.secondEquipmentPanelName}; ${row.secondEquipmentPanelName}`;
      }
      return '';
    },
    async getData(id) {
      await incidentsApi.getIncident(id)
        .then((response) => {
          this.incident = response.data;
          this.incident.incidentTime = this.dateGetter(this.incident.incidentTime);
          this.incident.incidentEndTime = this.dateGetter(this.incident.incidentEndTime);
          this.primaryEquipmentsTableData = response.data.primaryEquipments.map((item) => ({
            name: item.name,
            energyObjectsString: item.energyObjectsString
          }));
          this.secondEquipmentPanelsTableData = response.data.secondEquipmentPanels.map((item) => ({
            name: item.name,
            energyObjectName: item.energyObjectName
          }));

          this.selectedPrimaryEquipments = response.data.primaryEquipments;
          this.selectedSecondEquipmentPanels = response.data.secondEquipmentPanels;
          // this.secondEquipmentPanels = response.data.secondEquipmentPanels;
        });
    },
    async saveIncident() {
      this.incident.secondEquipmentPanels = [...new Set(this.selectedSecondEquipmentPanels.map((item) => item.id))];
      this.incident.equipments = [...new Set(this.selectedPrimaryEquipments.map((item) => item.primaryEquipmentId))];

      const valid = await this.validateForm();
      if (!valid) return;

      this.loading = true;

      if (this.incident.id == null) {
        await incidentsApi.createIncident(this.getSaveable())
          .then(async (response) => {
            this.$router.push({
              name: 'IncidentItem',
              params: { id: response.data }
            })
              .then(() => null);
            await this.getData(response.data);
            this.loading = false;
            this.$router.go();
          });
      } else {
        this.loading = true;
        await incidentsApi.updateIncident(this.getSaveable())
          .then(() => {
            this.loading = false;
            this.$router.go();
          });
      }
    },
    getSaveable() {
      const result = cloneDeep(this.incident);
      result.incidentTime = this.incident.incidentTime ? this.dateSetter(this.incident.incidentTime) : ' ';
      result.incidentEndTime = this.incident.incidentEndTime ? this.dateSetter(this.incident.incidentEndTime) : ' ';
      return result;
    },

    async getEvents() {
      this.loading = true;
      const res = await eventsApi.getEventsByIncident(this.id, this.eventsShowDeleted);
      if (res.data) {
        this.events = res.data;
      }
      this.loading = false;
    },
    handlePrimaryEquipmentModalOpen() {
      this.primaryEquipmentModalVisible = true;
    },
    handleSecondEquipmentPanelModalOpen() {
      this.modalKey += 1;
      this.secondEquipmentPanelModalVisible = true;
    },
    changeSep(event) {
      this.secondEquipmentPanelsTableData = event;
      this.selectedSecondEquipmentPanels = event;
      this.$refs.incidentForm.fields.find((f) => f.prop === 'primaryEq')
        .clearValidate();
      this.secondEquipmentPanelModalVisible = false;
    },

    changePe(event) {
      this.primaryEquipmentsTableData = event.map((item) => ({
        name: item.name,
        energyObjectsString: item.energyObjectsString
      }));
      this.selectedPrimaryEquipments = event;
      this.$refs.incidentForm.fields.find((f) => f.prop === 'primaryEq')
        .clearValidate();
      this.primaryEquipmentModalVisible = false;
    },

    handleDelete() {
      this.$confirm('Вы действительно хотите удалить запись ? При удалении аварии будут также удалены связанные события', 'Подтверждение', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      })
        .then(async () => {
          this.loading = true;
          await incidentsApi.delete(this.id);
          this.$router.push({ name: 'Incidents' });
        })
        .catch(() => {
        });
    },
    async handleCancel() {
      if (!this.incident.id) {
        this.$router.push({ name: 'Incidents' });
      } else {
        this.loading = true;
        await this.getData(this.incident.id)
          .then(() => {
            this.loading = false;
          });
      }
    },
    handleEventDelete(event) {
      this.$confirm('Вы действительно хотите удалить запись?', 'Подтверждение', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      })
        .then(async () => {
          const deleteResult = await eventsApi.delete(event.id);
          if (deleteResult.data.succeeded === false) {
              this.$confirm('Для данной аварии создано только одно событие. При удалении события будет также удалена связанная авария', 'Подтверждение', {
                confirmButtonText: 'ОК',
                cancelButtonText: 'Отмена',
                type: 'warning',
              }).then(async () => {
                await incidentsApi.delete(this.incident.id);
                this.$router.push({ name: 'Incidents' });
              }).catch(() => { });
          } else {
            this.getEvents()
              .then(() => {
                this.getData(this.incident.id)
                  .then(() => {
                    this.loading = false;
                  });
              });
          }
        })
        .catch(() => {
        });
    },
    handleEventRestore(event) {
      this.$confirm('Вы действительно хотите восстановить запись? При восстановлении события будет восстановлена карточка аварии.', 'Подтверждение', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      })
        .then(async () => {
          await eventsApi.restore(event.id);
          this.getEvents();
        })
        .catch(() => {
        });
    },
    async validateForm() {
      const valid = await this.$refs.incidentForm.validate();
      return valid;
    },
    entityJsonCompareToHtml(entityJson, changedEntityJson) {
      let result = '';
      const entity = JSON.parse(entityJson);
      const changedEntity = JSON.parse(changedEntityJson);

      const entityDiff = diff.getDiff(entity, changedEntity, true);

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const prop in changedEntity) {
        const change = entityDiff.find((df) => df.path[0] === prop);

        if (change) {
          result += `
            <div>
              <span class="updated">
                <span class="key">${prop}</span>:
                <span>${entity[prop]}</span> =>
                <span>${changedEntity[prop]}</span>
              </span>
            </div>`;
        } else {
          result += `
            <div>
              <span class="key">${prop}</span>: <span>${entity[prop]}</span>
            </div>`;
        }
      }
      return result;
    },
    entityJsonToHtml(json) {
      let result = '';
      const entity = JSON.parse(json);

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const prop in entity) {
        result += `
          <div>
            <span class="key">${prop}</span>: <span>${entity[prop]}</span>
          </div>`;
      }
      return result;
    }
  },

};
</script>
<style scoped lang="scss">
.el-tabs__header.is-top {
  margin: 0px !important;
}

/*button.el-button.el-button--text {
  color: #303133;
  margin: 0px 15px;
}*/
button.el-button.el-button--text:first-child {
  margin-left: 0px;
}

.el-col > span {
  font-size: 12px;
}

/*button.el-button.el-button--text:hover {
  color: #3078bf;
}*/
div#pane-0 .el-dialog__body {
  padding-top: 0px !important;

}

div#pane-0 .el-dialog__header {
  padding-bottom: 20px !important;
}

.tdWraper > span {
  margin: auto 5px;
}

.tdWraper {
  display: flex;
}

.el-tree-node {
  padding: 5px 0px;
}

.el-tree {
  width: 100%;
}

.tree-container {
  overflow-y: auto;
  max-height: 40vh;
  min-height: 40vh;
}

.el-dialog__body {
  padding-bottom: 20px !important;
}

.dialog-footer {
  display: flex;
  justify-content: center;
}

.filter--container {
  align-items: flex-start;

  span {
    min-width: 12rem;
  }
}
</style>
