<template>
  <div class="my-flex-container-column">
    <el-row class="m-0" :gutter="20">
      <el-col v-if="userCanEdit" :span="3">
        <el-button @click="handleCreate" type="text" icon="el-icon-circle-plus-outline" :disabled="mergeMode">Добавить</el-button>
      </el-col>
      <el-col :span="3">
      <el-button @click="handleDownLoad" type="text"
                  :loading="loading"
                   icon="el-icon-download">Выгрузить
        </el-button>
      </el-col>
      <el-col v-if="userCanEdit" :span="3">
        <el-button @click="handleMergeMode" type="text" icon="el-icon-share">Объединить</el-button>
      </el-col>
      <el-col v-if="userCanEdit && mergeMode" :span="3">
        <el-button @click="handleMergeModeCancel" type="text" icon="el-icon-circle-close">Отменить</el-button>
      </el-col>
      <el-col :span="12">
        <pagination style="text-align: center"
                    :total-count="totalCount"
                    :page-size.sync="pageSize"
                    :current-page.sync="pageNumber"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" />
      </el-col>
    </el-row>
    <div>
      <el-button @click="toggleAllNodes" type="text" class="ml-2">Свернуть/развернуть всё</el-button>
        <span class="total_items">Всего: {{this.secondEquipmentPanelsCount}}</span>
    </div>
    <div v-loading="loading" class="my-flex-data-content">
      <infinite-tree
          ref="tree"
          :data="secondEquipmentPanels"
          :style="{'height' : '100%'}"
          @on-view-click="handleView"
          @on-check-click="handleCheck"
        >
          <div class="infinite-tree-node" slot-scope="{ node }">{{ node.color }}</div>
        </infinite-tree>

    <second-equipment-panel-modal @on-update="getSecondEquipmentPanels()" @on-create="getSecondEquipmentPanels()" @on-delete="getSecondEquipmentPanels()"
                                  v-model="dialogVisible" :secondEquipmentPanel="dialogSecondEquipmentPanel"></second-equipment-panel-modal>
    <second-equipment-panels-merge-modal v-model="dialogMergeVisible" :items="mergingItems" @on-merge="handleMerge($event)" @on-merge-item-remove="handleMergeItemRemove($event)"></second-equipment-panels-merge-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import secondEquipmentPanelsApi from '@/api/nsi/secondEquipmentPanels';
import pagination from '@/components/Ui/Pagination';
import infiniteTree from '@/components/Ui/InfiniteTree/InfiniteTree';
import dateHelpers from '@/mixins/dateHelpers';
import io from '@/utilities/fileIO';
import reportsApi from '@/api/reports';
import xlsUpDownHelper from '@/mixins/xlsUpDownHelper';
import SecondEquipmentPanelModal from './SecondEquipmentPanelModal';
import SecondEquipmentPanelsMergeModal from './SecondEquipmentPanelsMergeModal';


export default {
  name: 'SecondEquipmentPanelsTable',
  props: {
    filterModel: { type: Object, default: () => {} },
    filterActive: { type: Boolean, default: () => true },
  },
  mixins: [dateHelpers, xlsUpDownHelper],
  components: {
    pagination,
    SecondEquipmentPanelModal,
    infiniteTree,
    SecondEquipmentPanelsMergeModal
    },
  data() {
    return {
      loading: false,
      secondEquipmentPanels: [],
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      sortField: 'name',
      sortDesc: false,
      dialogVisible: false,
      dialogSecondEquipmentPanel: {},
      mergeMode: false,
      mergingItems: [],
      dialogMergeVisible: false,
      secondEquipmentPanelsCount: 0
    };
  },
  watch: {
    filterModel: {
      handler() {
        this.getSecondEquipmentPanels();
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters('identity', ['userHasRole']),
    userCanEdit() {
      return this.userHasRole('NsiCorpEdit,NsiFullAccess');
    },
    treeContainer() {
        return this.$refs.tree;
    }
  },
  async mounted() {
    // this.$nextTick(() => {
    //   this.tableHeight = this.getTableHeight();
    // });
    await this.getSecondEquipmentPanels();
    // this.tableHeight = this.getTableHeight();
  },
  methods: {
    async handleDownLoad() {
      this.loading = true;
      const params = {
        nameFilter: this.filterModel.nameFilter,
        eoIdFilter: this.filterModel.eoIdFilter,
        productAssetModelIdFilter: this.filterModel.productAssetModelIdFilter,
        skipEoObject: this.filterModel.skipEoObject,
        isLocalNsiFilter: this.filterModel.isLocalNsiFilter,
        duOrganizationName: this.filterModel.duOrganizationName,
        dvOrganizationName: this.filterModel.dvOrganizationName,
        baseVoltageFilter: this.filterModel.baseVoltageFilter,
        eoRegionFilter: this.filterModel.eoRegionFilter,
        ownerOrganizationsFilter: this.filterModel.ownerOrganizationsFilter,
        registrationGroupFilter: this.filterModel.registrationGroupFilter,
        executionsFilter: this.filterModel.executionsFilter,  
        techAdministrationOrganizationsFilter: this.filterModel.techAdministrationOrganizationsFilter,
        techManagementOrganizationsFilter: this.filterModel.techManagementOrganizationsFilter,
        technicalOrganizationsFilter: this.filterModel.technicalOrganizationsFilter,
        startCommissioningTimeRange: this.filterModel.periodCommissioningTimeRange?.length > 0 ? this.toUserDate(this.filterModel.periodCommissioningTimeRange[0], 'YYYY-MM-DD') : null,
        endCommissioningTimeRange: this.filterModel.periodCommissioningTimeRange?.length > 0 ? this.toUserDate(this.filterModel.periodCommissioningTimeRange[1], 'YYYY-MM-DD') : null,
        startDecommissioningTimeRange: this.filterModel.periodDecommissioningTimeRange?.length > 0 ? this.toUserDate(this.filterModel.periodDecommissioningTimeRange[0], 'YYYY-MM-DD') : null,
        endDecommissioningTimeRange: this.filterModel.periodDecommissioningTimeRange?.length > 0 ? this.toUserDate(this.filterModel.periodDecommissioningTimeRange[1], 'YYYY-MM-DD') : null,
        showDecommissionedSE: this.filterModel.showDecommissionedSE
      };
      const res = await reportsApi.createSecondEquipmentPanelReport(params);
      if (res.data.exceptionDetails) {
        this.$alert(`При формировании отчета произошла ошибка: ${res.data.exceptionDetails}`, 'Ошибка');
      } else {
        io.downloadFile(res.data.content, res.data.contentType, res.data.fileName);
      }
      this.loading = false;      
    },
    
    async getSecondEquipmentPanels() {
      this.loading = true;
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        nameFilter: this.filterModel.nameFilter,
        eoIdFilter: this.filterModel.eoIdFilter,
        productAssetModelIdFilter: this.filterModel.productAssetModelIdFilter,
        skipEoObject: this.filterModel.skipEoObject,
        isLocalNsiFilter: this.filterModel.isLocalNsiFilter,
        duOrganizationName: this.filterModel.duOrganizationName,
        dvOrganizationName: this.filterModel.dvOrganizationName,
        baseVoltageFilter: this.filterModel.baseVoltageFilter,
        sortField: this.sortField,
        sortDesc: this.sortDesc,

        eoRegionFilter: this.filterModel.eoRegionFilter,
        ownerOrganizationsFilter: this.filterModel.ownerOrganizationsFilter,
        registrationGroupFilter: this.filterModel.registrationGroupFilter,
        executionsFilter: this.filterModel.executionsFilter,  
        techAdministrationOrganizationsFilter: this.filterModel.techAdministrationOrganizationsFilter,
        techManagementOrganizationsFilter: this.filterModel.techManagementOrganizationsFilter,
        technicalOrganizationsFilter: this.filterModel.technicalOrganizationsFilter,
        startCommissioningTimeRange: this.filterModel.periodCommissioningTimeRange?.length > 0 ? this.toUserDate(this.filterModel.periodCommissioningTimeRange[0], 'YYYY-MM-DD') : null,
        endCommissioningTimeRange: this.filterModel.periodCommissioningTimeRange?.length > 0 ? this.toUserDate(this.filterModel.periodCommissioningTimeRange[1], 'YYYY-MM-DD') : null,
        startDecommissioningTimeRange: this.filterModel.periodDecommissioningTimeRange?.length > 0 ? this.toUserDate(this.filterModel.periodDecommissioningTimeRange[0], 'YYYY-MM-DD') : null,
        endDecommissioningTimeRange: this.filterModel.periodDecommissioningTimeRange?.length > 0 ? this.toUserDate(this.filterModel.periodDecommissioningTimeRange[1], 'YYYY-MM-DD') : null,
        showDecommissionedSE: this.filterModel.showDecommissionedSE
      };
      const res = await secondEquipmentPanelsApi.getSecondEquipmentPanelsWithPagination(params);
      if (res.data) {
        this.secondEquipmentPanels = res.data.items;
        this.totalCount = res.data.totalCount;
        this.secondEquipmentPanelsCount = res.data.treeItemsCount;

        this.treeContainer.tree.loadData(this.secondEquipmentPanels);
        this.applyIsMerging(this.mergeMode);
      }
      this.loading = false;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getSecondEquipmentPanels();
    },
    async handleCurrentChange() {
      await this.getSecondEquipmentPanels();
    },
    async handleSortChange(event) {
      if (event.order === null) {
        this.sortDesc = null;
        this.sortField = null;
      } else {
        this.sortDesc = event.order === 'descending';
        this.sortField = event.prop;
      }

      await this.getSecondEquipmentPanels();
    },
   async handleView(node) {
      const res = await secondEquipmentPanelsApi.getSecondEquipmentPanelItem(node.baseId);
      res.data.idlePeriods.forEach((x) => {
        if (x.total === undefined) {
          const startTime = new Date(x.startDate).getTime();
          const stopTime = new Date(x.stopDate).getTime();
          const hours = Math.floor((stopTime - startTime) / (3600 * 1000));
          const minutes = Math.floor(((stopTime - startTime) / (60 * 1000)) % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
          x.total = `${hours}:${minutes}`;
        }
        x.startDate = this.dateGetter(x.startDate);
        x.stopDate = this.dateGetter(x.stopDate);
      });
      this.dialogSecondEquipmentPanel = res.data;
      this.dialogVisible = true;
    },
    handleCheck(node) {
      if (node.state.checked) {
        this.mergingItems.push(node);

        const nodes = this.treeContainer.tree.nodes.filter((t) => t.id === node.id);
        if (nodes && nodes.length > 0) {
          nodes.forEach((n) => {
            n.state.checked = true;
          });

          this.treeContainer.rerender();
        }
      } else {
        const index = this.mergingItems.findIndex((x) => x.id === node.id);
        if (index > -1) {
          this.mergingItems.splice(index, 1);

          const nodes = this.treeContainer.tree.nodes.filter((t) => t.id === node.id);
          if (nodes && nodes.length > 0) {
            nodes.forEach((n) => {
              n.state.checked = false;
            });

            this.treeContainer.rerender();
          }
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    handleCreate(index, secondEquipmentPanel) {
        this.dialogSecondEquipmentPanel = {
          id: null, 
          name: null, 
          startDate: null, 
          endDate: null, 
          productAssetModelId: '', 
          energyObjectId: '', 
          // normallyInService: false, 
          ownerOrganizations: [], 
          technicalOrganizations: [], 
          technologicalAdministrationOrganizations: [], 
          technologicalManagementOrganizations: [], 
          dispatchAdministrationOrganizations: [], 
          dispatchManagementOrganizations: [], 
          isAip: false, 
          idlePeriods: []
        };
        this.dialogVisible = true;
        this.$root.$emit('addEvent', {
          editMode: true,
        });
    },
    applyIsMerging(isMerging) {
      const { nodes } = this.treeContainer.tree;

      nodes.forEach((node) => {
        if (node.nodeType === 'secondEquipmentPanel') {
          if (isMerging && this.mergingItems.some((t) => t.id === node.id)) {
            node.state.checked = true;
          }

          if (!isMerging) node.state.checked = false;

          node.сheckAllowed = isMerging;
          node.viewAllowed = !isMerging;
        }
      });

      this.treeContainer.rerender();
    },
    handleMergeItemRemove(item) {
      const index = this.mergingItems.findIndex((x) => x.id === item.id);
      if (index > -1) {
        this.mergingItems.splice(index, 1);
        this.applyIsMerging(this.mergeMode);

        if (this.mergingItems.length <= 1) {
          this.dialogMergeVisible = false;
          this.$alert('Необходимо выбрать 2 или более записей', 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
            customClass: 'alert-30',
          });
        }
      }
    },
    async handleMerge(data) {
      if (data.secondaryItems.some((x) => x.isAip)) {
        this.$alert('Невозможно объединить несколько корпоративных записей или использовать корпоративную запись в качестве присоединяемой', 'Ошибка', {
          confirmButtonText: 'OK',
          type: 'warning',
          customClass: 'alert-30',
        });
        return;
      }

      this.loading = true;

      const mainItemId = data.mainItem.id.substr(data.mainItem.id.length - 36);

      const res = await secondEquipmentPanelsApi.mergeSecondEquipmentPanels(mainItemId, data.secondaryItems.map((x) => x.id.substr(x.id.length - 36)));
      if (!res.data.succeeded) {
        this.$alert(res.data.resultMessage, 'Ошибка', {
          confirmButtonText: 'OK',
          type: 'warning',
          customClass: 'alert-30',
        });
      } else {
        this.dialogMergeVisible = false;
        this.handleMergeModeCancel();
        await this.getSecondEquipmentPanels();
      }
      this.loading = false;
    },
    handleMergeMode() {
      if (!this.mergeMode) {
        this.mergeMode = true;
        this.applyIsMerging(this.mergeMode);
      } else {
        if (this.mergingItems.length <= 1) {
          this.$alert('Необходимо выбрать 2 или более записей', 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
            customClass: 'alert-30',
          });
        } else {
          this.dialogMergeVisible = true;
        }
      }
    },
    handleMergeModeCancel() {
      this.mergingItems.splice(0);
      this.mergeMode = false;
      this.applyIsMerging(this.mergeMode);
    },
    toggleAllNodes() {
      this.$refs.tree.toggleNodes();
      this.applyChecking();
    }
  }
};
</script>

<style scoped>
.total_items {
   float: right;
   color: #909399;
   font-family: Montserrat-Regular;
   font-size: 14px;
   margin-top: 12px;
   margin-right: 8px;
}
</style>
