<template>
  <div class="my-flex-container-column">
    <el-button
      type="text"
      @click="clearSelectedSecondEquipmentPanels"
      icon="el-icon-circle-close"
    >
      Очистить все
    </el-button>
    <div class="selected-tags">
      <el-tag
        v-for="tag in selectedSecondEquipmentPanels"
        :key="tag.id"
        size="small"
        :closable="!tag.disabled"
        @close="handleClose(tag)"
        class="mr-1 mb-1"
      >
        {{ tag.name }}
      </el-tag>
    </div>

      <el-row class="m-0" :gutter="20">
        <el-col :span="12">
          <pagination
            style="text-align: center"
            :total-count="totalCount"
            :page-size.sync="pageSize"
            :current-page.sync="pageNumber"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </el-col>
      </el-row>
      <div >
        <el-button @click="toggleAllNodes" type="text" class="ml-2">Свернуть/развернуть всё</el-button>
        <span class="total_items">Всего: {{this.secondEquipmentPanelsCount}}</span>
      </div>
      <div v-loading="loading" class="my-flex-data-content">
      <infinite-tree
        ref="tree"
        :data="secondEquipmentPanels" 
        :style="{'height' : '100%'}"
        @on-check-click="handleCheck"
        @on-open-node="applyChecking"
      >
        <div class="infinite-tree-node" slot-scope="{ node }">{{ node.color }}</div>
      </infinite-tree>
      <!-- <div>
        <el-button @click="this.elTreeCollapse" type="text" class="ml-2">Свернуть/развернуть всё</el-button>
        <span v-if="!this.filterModel.skipEoObject" class="total_items">Всего: {{this.secondEquipmentPanelsCount}}</span>
      </div>
        <el-container class="tree-container mb-3">
          <el-tree
            :data="secondEquipmentPanels"
            v-loading="loading"
            show-checkbox
            node-key="id"
            default-expand-all
            :default-checked-keys="selectedSecondEquipmentPanels.map((item) => item.id)"
            @check-change="handleCheckChange"
            ref="tree"
          >
            <span class="custom-tree-node" slot-scope="{ node }">
              <div class="tdWraper">
                <span>
                  <i v-if="node.level == 1"><FactoryIcon /></i>
                  <i v-if="node.level == 2"><ShieldIcon /></i>
                </span>
                <span>{{ node.data.name }}</span>
              </div>
            </span>
          </el-tree>
        </el-container>-->
    </div>
    <div class="dialog-footer">
      <el-button type="primary" @click="accept">Применить</el-button>
      <el-button type="default" @click="cancel">Отменить</el-button>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import dateHelpers from '@/mixins/dateHelpers';
import secondEquipmentPanelsApi from '@/api/eventsAndIncidents/secondEquipmentPanels';
// import FactoryIcon from '@/assets/icons/factory.svg';
// import ShieldIcon from '@/assets/icons/shield.svg';
import pagination from '@/components/Ui/Pagination';
import infiniteTree from '@/components/Ui/InfiniteTree/InfiniteTree';

export default {
  name: 'SelectSecondEquipmentPanelTable',
  props: {
    items: { type: Array, default: () => [] },
    incidentId: { type: String, default: () => '' },
    filterActive: { type: Boolean, default: () => true },
    filterModel: { type: Object, default: () => { } },
  },
  mixins: [dateHelpers],
  components: {
    pagination,
    infiniteTree
    // FactoryIcon,
    // ShieldIcon,
  },
  watch: {
    filterModel: {
        handler() {
            this.getSecondEquipmentPanels();
        },
        deep: true
    }
  },
  computed: {
    treeContainer() {
      return this.$refs.tree;
    }
  },
  mounted() {
    this.acceptedState = cloneDeep(this.items);
    this.selectedSecondEquipmentPanels = this.items;
    this.getSecondEquipmentPanels();
  },
  data() {  
    return {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      sortField: 'name',
      sortDesc: false,
      checked: false,
      loading: false,
      secondEquipmentPanels: [],
      selectedSecondEquipmentPanels: [],
      acceptedState: [],
      secondEquipmentPanelsCount: 0,
      tableSizeConstant: 270,
    };
  },
  methods: {
    async getSecondEquipmentPanels() {
      this.loading = true;
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        nameFilter: this.filterModel.nameFilter,
        eoIdFilter: this.filterModel.eoIdFilter,
        productAssetModelIdFilter: this.filterModel.productAssetModelIdFilter,
        skipEoObject: this.filterModel.skipEoObject,
        isLocalNsiFilter: this.filterModel.isLocalNsiFilter,
        duOrganizationName: this.filterModel.duOrganizationName,
        dvOrganizationName: this.filterModel.dvOrganizationName,
        baseVoltageFilter: this.filterModel.baseVoltageFilter,
        sortField: this.sortField,
        sortDesc: this.sortDesc,
        eoRegionFilter: this.filterModel.eoRegionFilter,
        ownerOrganizationsFilter: this.filterModel.ownerOrganizationsFilter,
        registrationGroupFilter: this.filterModel.registrationGroupFilter,
        executionsFilter: this.filterModel.executionsFilter,  
        techAdministrationOrganizationsFilter: this.filterModel.techAdministrationOrganizationsFilter,
        techManagementOrganizationsFilter: this.filterModel.techManagementOrganizationsFilter,
        technicalOrganizationsFilter: this.filterModel.technicalOrganizationsFilter,
        startCommissioningTimeRange: this.filterModel.periodCommissioningTimeRange?.length > 0 ? this.toUserDate(this.filterModel.periodCommissioningTimeRange[0], 'YYYY-MM-DD') : null,
        endCommissioningTimeRange: this.filterModel.periodCommissioningTimeRange?.length > 0 ? this.toUserDate(this.filterModel.periodCommissioningTimeRange[1], 'YYYY-MM-DD') : null,
        startDecommissioningTimeRange: this.filterModel.periodDecommissioningTimeRange?.length > 0 ? this.toUserDate(this.filterModel.periodDecommissioningTimeRange[0], 'YYYY-MM-DD') : null,
        endDecommissioningTimeRange: this.filterModel.periodDecommissioningTimeRange?.length > 0 ? this.toUserDate(this.filterModel.periodDecommissioningTimeRange[1], 'YYYY-MM-DD') : null,
        showDecommissionedSE: this.filterModel.showDecommissionedSE,
        incidentId: this.incidentId
      };
      const res = await secondEquipmentPanelsApi.getSecondEquipmentPanels(params);
      //   this.pageNumber,
      //   this.pageSize,
      //   this.sortField,
      //   this.sortDesc,
      //   this.filterModel.nameFilter,
      //   this.filterModel.eoNameFilter,
      //   this.filterModel.productAssetModelNameFilter,
      //   this.filterModel.isLocalNsiFilter,
      //   this.incidentId
      // );
      if (res.data) {
        this.secondEquipmentPanels = res.data.items;
        this.totalCount = res.data.totalCount;
        this.secondEquipmentPanelsCount = res.data.treeItemsCount;

        this.treeContainer.tree.loadData(this.secondEquipmentPanels);
        this.applyChecking();
      }
      this.loading = false;
    },
    applyChecking() {
      const { nodes } = this.treeContainer.tree;
      nodes.forEach((node) => {
        if (node.nodeType === 'secondEquipmentPanel') {
          if (this.selectedSecondEquipmentPanels.some((t) => t.id === node.id)) {
            node.state.checked = true;
            node.parent.state.checked = node.state.checked;
          } else {
            node.state.checked = false;
          }
          node.сheckAllowed = true;
          node.viewAllowed = false;
          node.parent.сheckAllowed = node.сheckAllowed;
        }
      });

      this.treeContainer.rerender();
    },
    clearSelectedSecondEquipmentPanels() {
      this.selectedSecondEquipmentPanels = this.selectedSecondEquipmentPanels.filter((s) => s.disabled === true);
      this.applyChecking();
      // this.selectedSecondEquipmentPanels = this.selectedSecondEquipmentPanels.filter((item) => item.disabled === true);
      // const childs = [];
      // this.$refs.tree.data.forEach((el) => el.children.forEach((ch) => childs.push(ch)));
      // childs.forEach((c) => this.$refs.tree.setChecked(c.id, false));
    },
    handleCheck(node) {
      if (node.state.checked) {
        const newSep = cloneDeep(node);
        newSep.parent = null;
        this.selectedSecondEquipmentPanels.push(newSep);

        const nodes = this.treeContainer.tree.nodes.filter((t) => t.nodeType === 'secondEquipmentPanel' && t.id === node.id);
        if (nodes && nodes.length > 0) {
          nodes.forEach((n) => {
            n.state.checked = true;
            n.parent.state.checked = true;
          });

          this.treeContainer.rerender();
        }
      } else {
        const index = this.selectedSecondEquipmentPanels.findIndex((x) => x.id === node.id);
        if (index > -1) {
          this.selectedSecondEquipmentPanels.splice(index, 1);

          const nodes = this.treeContainer.tree.nodes.filter((t) => t.nodeType === 'secondEquipmentPanel' && t.id === node.id);
          if (nodes && nodes.length > 0) {
            nodes.forEach((n) => {
              n.state.checked = false;
              n.parent.state.checked = false;
            });

            this.treeContainer.rerender();
          }
        }
      }
      this.$emit('on-update', this.selectedSecondEquipmentPanels);
    },
    // handleCheckChange(data, checked) {
    //   if (checked) {
    //     if (data.children == null) {
    //       this.selectedSecondEquipmentPanels.push(data);
    //       // обход дерева
    //       let childs = [];
    //       this.$refs.tree.data.forEach((el) => el.children.forEach((ch) => childs.push(ch)));
    //       childs = childs.filter((i) => i.secondEquipmentPanelId === data.id);
    //       childs.forEach((c) => this.$refs.tree.setChecked(c.id, true));
    //     }
    //   } else {
    //     if (data.children == null) {
    //       this.$nextTick(() => {
    //         this.selectedSecondEquipmentPanels = this.selectedSecondEquipmentPanels.filter((item) => item.id !== data.id);

    //         let childs = [];
    //         this.$refs.tree.data.forEach((el) => el.children.forEach((ch) => childs.push(ch)));
    //         childs = childs.filter((i) => i.id !== data.id);
    //         childs.forEach((c) => this.$refs.tree.setChecked(c.id, false));
    //       });
    //     }
    //   }
    // },
    handleClose(tag) {
      this.selectedSecondEquipmentPanels = this.selectedSecondEquipmentPanels.filter((s) => s.id !== tag.id);
      this.applyChecking();
      // this.$nextTick(() => {
      //   this.selectedSecondEquipmentPanels = this.selectedSecondEquipmentPanels.filter((item) => item.id !== tag.id);

      //   let childs = [];
      //   this.$refs.tree.data.forEach((el) => el.children.forEach((ch) => childs.push(ch)));
      //   childs = childs.filter((i) => i.secondEquipmentPanelId !== tag.id);
      //   childs.forEach((c) => this.$refs.tree.setChecked(c.id, false));
      // });
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getSecondEquipmentPanels();
    },
    async handleCurrentChange() {
      await this.getSecondEquipmentPanels();
      const isNodesOpen = await this.$refs.tree.isNodesOpen();
      if (!isNodesOpen) {
        await this.toggleAllNodes();
        await this.$refs.tree.toggleNodes();
      }
      this.applyChecking();
    },
    async handleSortChange(event) {
      if (event.order === null) {
        this.sortDesc = null;
        this.sortField = null;
      } else {
        this.sortDesc = event.order === 'descending';
        this.sortField = event.prop;
      }
      await this.getSecondEquipmentPanels();
    },
    cancel() {
      this.$emit('change', this.acceptedState);
    },
    accept() {
      this.selectedSecondEquipmentPanels.forEach((item) => item.confirmed === true);
      this.$emit('change', this.selectedSecondEquipmentPanels);
    },
    async toggleAllNodes() {
      this.$refs.tree.toggleNodes();
      this.applyChecking();
    }
  },
};
</script>

<style scoped>
.el-tabs__header.is-top {
  margin: 0px !important;
}
button.el-button.el-button--text:first-child {
  margin-left: 0px;
}
.el-col > span {
  font-size: 12px;
}
div#pane-0 .el-dialog__body {
  padding-top: 0px !important;
}
div#pane-0 .el-dialog__header {
  padding-bottom: 20px !important;
}
.tdWraper > span {
  margin: auto 5px;
}
.tdWraper {
  display: flex;
}
.el-tree-node {
  padding: 5px 0px;
}
.el-tree {
  width: 100%;
}
.tree-container {
  overflow-y: auto;
  max-height: 40vh;
  min-height: 40vh;
}
.el-dialog__body {
  padding-bottom: 20px !important;
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
.total_items {
   float: right; 
   color: #909399;
   font-family: Montserrat-Regular;
   font-size: 14px;
   margin-top: 12px;
   margin-right: 8px;
}
</style>
