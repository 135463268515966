<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false" >
      <div slot="title">
          <span class="mr-2">Устройство РЗА</span>
          <template v-if="editMode">
            <el-button @click="saveSecondEquipmentCard()" :disabled="loading" class="back_action_panel--first_element" type="text">
              <SaveIcon />Сохранить
            </el-button>
            <el-button @click="closeModal" type="text">
              <CancelIcon />Отменить
            </el-button>
          </template>
      </div>
      <el-form :rules="this.rules" v-loading="this.loading" ref="secondEquipmentCardForm" :model="secondEquipmentCard" label-position="top">
        <el-container>
          <el-form-item prop="secondEquipmentName" label="Устройство РЗА:">
            <el-input size="small" disabled v-model="secondEquipmentCard.secondEquipmentName"></el-input>
          </el-form-item>

          <el-form-item prop="estimationId" label="Оценка действия:" class="estimation">
            <el-select :disabled="!editMode"
                       popper-class="search-select"
                       size="small"
                       v-model="secondEquipmentCard.estimationId" placeholder="">
              <el-option
                v-for="item in estimations"
                :key="item.id"
                :label="item.code + ' (' + item.name + ')'"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="cardInformation" label="Дополнительная информация:">
            <el-select :disabled="!editMode" multiple v-model="secondEquipmentCard.cardInformation" size="small" placeholder="">
              <el-option
                v-for="item in cardInformations"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :disabled="item.isDisabled">
              </el-option>
            </el-select>
          </el-form-item>
          <span>
             <label class="el-form-item__label">Технические причины: <span v-if="organizationalReasonsRequired" style="color: #f56c6c">*</span></label>
          <el-button v-if="editMode" class="ml-2" type="primary" style="width: 100px" size="mini" @click="showTechnicalReasonModal" >
            Выбрать
          </el-button>
          </span>
          <el-form-item prop="technicalReasons">
          <div class="selected-tags">
              <el-tag v-for="tag in secondEquipmentCard.technicalReasons"
                      :key="tag.id"
                      :closable="false"
                      size="small"
                      class="mr-1 mb-1">
                {{tag.code}}
              </el-tag>
          </div>
          </el-form-item>

          <el-form-item prop="misoperationReasons" label="Детализированная причина неправильной работы:">
            <el-select :disabled="!editMode" popper-class="search-select" size="small" v-model="secondEquipmentCard.misoperationReasons" multiple placeholder="">
              <el-option
                v-for="item in misoperationReasons"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="organizationalReasons">
            <el-row class="mt-0">
              <label class="el-form-item__label">
                Организационные причины: <span v-if="organizationalReasonsRequired" style="color: #f56c6c">*</span>
              </label>
                <el-button
                  v-if="editMode"
                  circle
                  icon="el-icon-plus"
                  size="small"
                  class="p-1 mb-1 ml-2"
                  @click="addNewRow">
                </el-button>
            </el-row>
            <el-col :span="24">
              <el-table
                :data="this.secondEquipmentCard.organizationalReasons"
                v-loading="this.loading"
                size="small"
                :default-sort = "{prop: 'code', order: 'ascending'}"
                style="width: 100%"
              >
                <el-table-column prop="code" label="Код причины">
                  <template slot-scope="props">
                    <el-select :disabled="!editMode" size="small" popper-class="search-select" v-model="props.row.code" placeholder="Выберете код причины">
                      <template v-for="group in staffCulpabilities">
                        <el-option-group
                          v-if="group.children.length > 0"
                          :key="group.id"
                          :label="group.name">
                            <el-option
                              v-for="item in group.children"
                              :key="item.id"
                              :label="item.code + ' (' + item.name + ')'"
                              :value="item.code">
                            </el-option>
                        </el-option-group>
                        <el-option
                            v-else
                            :key="group.id"
                            :label="group.code + ' (' + group.name + ')'"
                            :value="group.code">
                        </el-option>
                      </template>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="category" label="Категория персонала">
                  <template slot-scope="props">
                    <el-select :disabled="!editMode" size="small" popper-class="search-select" v-model="props.row.category" placeholder="Выберете категорию">
                        <el-option
                          v-for="item in staffCategories"
                          :key="item.id"
                          :label="item.code + ' (' + item.name + ')'"
                          :value="item.id">
                        </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="organization" label="Организация">
                  <template slot-scope="scope">
                      <remote-search-input
                        v-model="scope.row.organization"
                        :key="scope.row.key"
                        :disabled="!editMode"
                        searchUrl="/api/organizations/SearchByName"
                        size="small"
                        return-prop="id"
                        placeholder="Выберите организацию"
                        :show-when-init="true"
                        :initial-value="scope.row.organizationName"
                        :start-length=1
                        :allow-create="false"
                        @label-update="scope.row.organizationName = $event"
                      />
                  </template>
                </el-table-column>
                <el-table-column width="42px">
                  <template slot-scope="scope">
                    <el-button v-if="editMode" @click="deleteRow(scope.$index)" type="text" icon="el-icon-delete"></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-form-item>
        </el-container>
      </el-form>
    </el-dialog>

    <select-technical-reasons-modal
    :key="key"
    v-model="selectTechnicalReasonModalVisible"
    :tree="technicalReasons"
    :selected-items="secondEquipmentCard.technicalReasons"
    @save="confirmSelectedTechnicalReasons($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';
import SaveIcon from '@/assets/icons/save.svg';
import CancelIcon from '@/assets/icons/cancel.svg';
import formatters from '@/mixins/formatters.js';
import dateHelpers from '@/mixins/dateHelpers';
import secondEquipmentCardsApi from '@/api/eventsAndIncidents/secondEquipmentCards';
import udSecondEquipmentCardsApi from '@/api/eventsAndIncidents/userDataEvents/secondEquipmentCards';
import SelectTechnicalReasonsModal from '@/views/EventsAndIncidents/Events/Modals/SelectTechnicalReasonsModal';

export default {
  name: 'SecondEquipmentCardModal',
  props: {
    value: { type: Boolean, default: () => false },
    id: { type: String, default: () => '' },
    eventCard: { type: Object, default: () => {} },
    secondEquipmentCard: { type: Object, default: () => {} },
    editMode: { type: Boolean, default: () => false },
  },
  mixins: [formatters, dateHelpers],
  components: {
    SaveIcon,
    CancelIcon,
    RemoteSearchInput,
    SelectTechnicalReasonsModal
  },
  watch: {
    'secondEquipmentCard.estimationId': function (newValue) {
      if (newValue) {
        this.handleEstimation(newValue);
      }
    }
  },
  computed: {
    ...mapGetters('dictionaries', ['cardInformations', 'misoperationReasons', 'estimations', 'technicalReasons', 'staffCategories', 'staffCulpabilities']),
    ...mapGetters('identity', ['userHasRole', 'userHasRoleGroup']),
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    allowStaffCategories() {
      return this.staffCategories.filter((x) => x.code === '00');
    },
  filteredEstimations() {
    return this.estimations.filter((x) => x.code === 'НЛ'
      || x.code === 'НО'
      || x.code === 'НИ'
      || x.code === 'НИ сигнал'
      || x.code === 'НЛ сигнал'
      || x.code === 'НО сигнал');
  }
  },
  created() {
    if (this.secondEquipmentCard && this.secondEquipmentCard.organizationalReasons) {
      this.secondEquipmentCard.organizationalReasons.forEach((f) => {
        f.key = this.newRandomKey();
      });
    }
  },
  data() {
    const estimationValidation = (rule, value, callback) => {
    const fEstimations = this.estimations.filter((x) => x.code === 'ПУ1'
                                                                                      || x.code === 'ПУ2'
                                                                                      || x.code === 'П'
                                                                                      || x.code === 'ПУ'
                                                                                      || x.code === 'ПН'
                                                                                      || x.code === 'П сигнал');

      if (this.secondEquipmentCard.estimationId === null) {
        return callback(new Error('Поле обязательно для заполнения'));
      } else if (fEstimations.some((x) => x.id === this.secondEquipmentCard.estimationId)
          && (this.secondEquipmentCard.technicalReasons.length > 0 || this.secondEquipmentCard.organizationalReasons.length > 0 || this.secondEquipmentCard.misoperationReasons.length > 0)) {
        return callback(new Error('Нельзя выбрать данную оценку, так как заполнены технические, организационные или детализированные причины неправильной работы'));
      } else {
        return callback();
      }
    };
    const orgReasonsValidation = (rule, value, callback) => {
      const reasons = this.secondEquipmentCard.organizationalReasons;
      const duplicate = this.searchDuplicateOrgReasons(reasons);
      const missed = this.searchMissedFieldsOrgReasons(reasons);
      if (duplicate) {
        return callback(new Error('Указаны две или более одинаковые организационные причины'));
      } else if (missed) {
        return callback(new Error('Все поля обязательны для заполнения'));
      }
      if (reasons.length === 0 && this.filteredEstimations.find((el) => this.secondEquipmentCard.estimationId === el.id)) {
        this.$refs.secondEquipmentCardForm.rules.organizationalReasons.required = true;
        return callback(new Error('Поле обязательно для заполнения'));
      }
      this.$refs.secondEquipmentCardForm.rules.organizationalReasons.required = false;
      return callback();
    };
    return {
      key: 0,
      loading: false,
      selectTechnicalReasonModalVisible: false,
      organizationalReasonsRequired: false,
      selectTechnicalReasons: [],
      rules: {
        estimationId: [
          { validator: estimationValidation, trigger: 'blur', required: true }
        ],
        organizationalReasons: [
          { validator: orgReasonsValidation, trigger: 'blur', required: false }
        ],
        technicalReasons:
          { trigger: 'blur', required: false, message: 'Поле обязательно для заполнения' },
      },
    };
  },
  methods: {
    handleEstimation(value) {
      if (this.filteredEstimations.find((el) => el.id === value)) {
        this.rules.technicalReasons.required = true;
        this.organizationalReasonsRequired = true;
      } else {
        this.rules.technicalReasons.required = false;
        this.organizationalReasonsRequired = false;
        this.$refs.secondEquipmentCardForm.clearValidate();
      }
    },
    async saveSecondEquipmentCard() {
      const valid = await this.validateForm();
      if (!valid) return;
      this.loading = true;
      this.secondEquipmentCard.technicalReasons = this.secondEquipmentCard.technicalReasons.map((i) => i.id);

      const saveAction = this.userHasRoleGroup('ExternalUser')
        ? udSecondEquipmentCardsApi.updateSecondEquipmentCard
        : secondEquipmentCardsApi.updateSecondEquipmentCard;

      await saveAction(this.secondEquipmentCard).then((response) => {
          this.dialogVisible = false;
          this.$emit('update', response);
          this.loading = false;
      });
    },
    async validateForm() {
      const valid = await this.$refs.secondEquipmentCardForm.validate();
      return valid;
    },
    searchDuplicateOrgReasons(array) {
      // eslint-disable-next-line no-shadow
      return array.map((value) => value.code + value.category + value.organization).some((value, index, array) => array.indexOf(value) !== array.lastIndexOf(value));
    },
    searchMissedFieldsOrgReasons(array) {
      let result = false;
      // eslint-disable-next-line func-names
      // eslint-disable-next-line consistent-return
      array.forEach((x) => {
        if (this.allowStaffCategories.find((el) => el.id === x.category)) {
          if (x.code === null || x.code === '' || x.category === null || x.category === '') {
            result = true;
          }
        } else if (x.organization === null || x.organization === '' || x.code === null || x.code === '' || x.category === null || x.category === '') {
          result = true;
        }
      });
      return result;
    },
    addNewRow() {
      this.secondEquipmentCard.organizationalReasons.unshift({
        key: this.newRandomKey(),
        code: '',
        category: '',
        organization: ''
      });
    },
    deleteRow(idx) {
      this.$delete(this.secondEquipmentCard.organizationalReasons, idx);
    },
    showTechnicalReasonModal() {
      this.selectTechnicalReasons = this.secondEquipmentCard.technicalReasons;
      this.key += 1;
      this.selectTechnicalReasonModalVisible = true;
    },
    confirmSelectedTechnicalReasons(event) {
      if (event) {
        this.secondEquipmentCard.technicalReasons = event;
        this.selectTechnicalReasonModalVisible = false;
      }
    },
    closeModal() {
      this.dialogVisible = false;
      this.$refs.secondEquipmentCardForm.clearValidate();
    },
    newRandomKey() {
      return new Date().getTime() + Math.floor(Math.random() * 10000);
    }
  },
};

</script>

<style scoped lang="scss">
svg {
  margin-right: .3rem;
  vertical-align: bottom;
}
.el-form{
     display: flex;
     align-content: space-between;
  }

.el-container{
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}
.el-form-item {
  display: flex;
  flex-direction: column;
  width:100%;
  margin-bottom: 1rem;
  margin-bottom: 0px;
    button {
        margin: auto;
    }
}
.el-form-item__error{
    width:100%;
    position: inherit !important;
}
</style>
<style lang="scss">
.estimation {
  .el-form-item__error{
    width:100%;
    position: inherit;
  }
}
.estimation.is-error {
    .el-form-item__content{
      flex-direction: column;
    }
}
</style>
