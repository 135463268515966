<template>
  <div class="my-flex-container-column">
    <events-filter @active-change="filterActive = $event" :filter-model="filterModel"
                   @clear-filters="clearFilters"/>
    <el-row class="m-0" :gutter="20">
      <el-col :span="4">
        <router-link
          v-if="userHasRoleGroup('ExternalUser') && userHasRole('IncidentsEdit')"
          :to="{ name: 'EventAdd', params: {
            incidentId: null
          } }">
          <el-button type="text" icon="el-icon-circle-plus-outline">Добавить</el-button>
        </router-link>
        <div v-else>&nbsp;</div>
      </el-col>
      <el-col :span="6">
        <pagination
          style="text-align: center"
          :total-count="totalCount"
          :page-size.sync="sortModel.pageSize"
          :current-page.sync="sortModel.pageNumber"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-col>
    </el-row>
    <div class="my-flex-data-content">
      <el-table v-loading="loading"
                style="width: 100%"
                height="100%"
                :data="events"
                :default-sort="this.sortModel"
                @sort-change="handleSortChange">

        <el-table-column width="64">
          <template slot-scope="scope">
            <router-link :to="{ name: 'EventItem', params: { id: scope.row.id } }">
              <el-button type="text" icon="el-icon-search"></el-button>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="number" label="№ события" width="150"
                         :formatter="eventNumberRenderer" sortable="custom"/>
        <el-table-column prop="eventTime" label="Дата и время" :formatter="dateTimeRenderer"
                         width="200" sortable="custom"/>
        <el-table-column prop="energySystem" label="Энергосистема" width="250"/>
        <el-table-column prop="energyObjects" label="Энергообъект"/>
        <el-table-column label="Оборудование/ЛЭП события">
          <template slot-scope="scope">
            {{ eventEquipment(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column v-if="showEventsMatch" label="Сопоставление">
          <template slot-scope="scope">
            <div v-for="el in scope.row.eventCorrelations" :key="el.key">
              <el-button type="text" @click="handleOpenCor(el.key)"> {{ el.value }}</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="baseVoltageName" label="Напряжение" width="120" sortable="custom"/>
        <el-table-column
          v-if="userHasRoleGroup('ExternalUser')"
          label="Требует корректировки"
          prop="needCorrect"
          width="150"
          :formatter="boolRenderer"
          align="center"
          header-align="center"
          sortable="custom"
        />
        <el-table-column v-if="filterModel.showDeleted" label="Дата и время удаления"
                         prop="deleteTime" width="200" :formatter="dateTimeRenderer">
        </el-table-column>
        <el-table-column width="42px">
          <template v-if="userHasRole('IncidentsEdit')" slot-scope="scope">
            <el-button v-if="!scope.row.deleteTime" type="text" icon="el-icon-delete"
                       @click="handleDelete(scope.row)"></el-button>
            <el-button v-else type="text" icon="el-icon-refresh-left"
                       @click="handleRestore(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <event-mapping-modal :my-store="this.myModalStore" @delete="getEvents"></event-mapping-modal>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import EventsFilter from '@/views/EventsAndIncidents/Events/EventsFilter';
import pagination from '@/components/Ui/Pagination';
import formatters from '@/mixins/formatters.js';
// import tableResize from '@/mixins/tableResize.js';
import dateHelpers from '@/mixins/dateHelpers';
import eventsApi from '@/api/eventsAndIncidents/events/';
import udEventsApi from '@/api/eventsAndIncidents/userDataEvents/';
import filterStorage from '@/utilities/filterStorage';
import { set } from 'vue';
import EventMappingModalStore from '@/views/EventsAndIncidents/EventMapping/EventMappingModalStore';
import EventMappingModal from '@/views/EventsAndIncidents/EventMapping/EventMappingModal.vue';

export default {
  name: 'Events',
  mixins: [formatters, dateHelpers],
  components: {
    EventMappingModal,
    pagination,
    EventsFilter
  },
  watch: {
    filterModel: {
      handler(newVal) {
        this.getEvents();
        filterStorage.saveFilter('events-filter', newVal);
      },
      deep: true
    },
    sortModel: {
      handler(newVal) {
        this.getEvents();
        filterStorage.saveFilter('events-sort', newVal);
      },
      deep: true
    },
    estimations: {
      handler(est) {
        this.myModalStore.setEstimations(est);
      },
      deep: true
    },
    staffCategories: {
      handler(staff) {
        debugger;
        this.myModalStore.setStaffCategories(staff);
      },
      deep: true
    },
  },
  data() {
    return {
      loading: false,
      events: [],
      totalCount: 0,
      sortModel: filterStorage.getFilterOrDefault('events-sort', {
        prop: 'eventTime',
        order: 'descending',
        pageNumber: 1,
        pageSize: 20,
      }),
      filterModel: {
        numberFilter: '',
        eventDateRange: [],
        startEventTimeFilter: '',
        endEventTimeFilter: '',
        primaryEquipmentFilter: [],
        secondEquipmentPanelFilter: [],
        baseVoltageFilter: [],
        energyObjectFilter: [],
        eoRegionFilter: [],
        showDeleted: false,
        dcOrganizationFilter: [],
        technicalOrganizationsFilter: [],
        psrTypeFilter: [],
        estimationFilter: [],
        shortCircuitFormFilter: [],
        shortCircuitPlaceFilter: [],
        showNeedCorrect: null,
        staffCategoryFilter: [],
        accidentEquipmentFilter: [],
        staffCulpabilitiesFilter: [],
        technicalReasonsFilter: [],
        secondEquipmentTypesFilter: [],
        misoperationReasonsFilter: [],
        eventSourcesFilter: '',
        eventSourcesFileNameFilter: [],
        ownerOrganizationsFilter: [],
        producerOrganizationsFilter: [],
        cardInformationFilter: [],
        executionsFilter: [],
        productAssetModelsFilter: [],
        registrationGroupFilter: [],
        faultedPhasesFilter: [],
        bmmnCheckTypesFilter: [],
        correlationStatuses: [],
      },
      filterActive: true,
      eventCard: {},
    };
  },
  computed: {
    ...mapGetters('identity', ['user', 'userHasRole', 'userHasRoleGroup']),
    ...mapGetters('dictionaries', ['estimations', 'staffCategories']),
    showEventsMatch() {
      return this.userHasRole('EventsMatchExternal');
    }
  },
  async created() {
    // this.$nextTick(() => {
    //   this.tableHeight = this.getTableHeight();
    // });
    this.myModalStore = new EventMappingModalStore(this.userHasRoleGroup('ExternalUser'), { toUserDate: this.toUserDate, toServerUtcDate: this.toServerUtcDate });
    const filter = filterStorage.getFilter('events-filter');
    const sort = filterStorage.getFilter('events-sort');
    if (filter) {
      this.clearFilters();
      // this.filterModel = { ...this.filterModel, ...filter };
      Object.keys(this.filterModel)
        .forEach((key) => {
          const value = filter[key] ? filter[key] : this.filterModel[key];
          set(this.filterModel, key, value);
        });
      if (sort) {
        // this.sortModel = { ...this.sortModel, ...sort };
        Object.keys(this.sortModel)
          .forEach((key) => {
            set(this.sortModel, key, sort[key]);
          });
      }
    } else {
      await this.getEvents(this.pageNumber, this.pageSize);
    }

    // this.tableHeight = this.getTableHeight();
  },
  methods: {
    eventEquipment(row) {
      if (row.primaryEquipmentName && !row.secondEquipmentPanelName) {
        return row.primaryEquipmentName;
      }
      if (!row.primaryEquipmentName && row.secondEquipmentPanelName) {
        return row.secondEquipmentPanelName;
      }
      if (row.primaryEquipmentName && row.secondEquipmentPanelName) {
        return `${row.secondEquipmentPanelName}; ${row.secondEquipmentPanelName}`;
      }
      return '';
    },
    async getEvents() {
      this.loading = true;
      if (this.filterModel.eoRegionFilter.length > 0 && !this.filterModel.eoRegionFilter.some((value) => filterStorage.isGuid(value))) {
        this.filterModel.eoRegionFilter = [];
      }
      const params = {
        pageNumber: this.sortModel.pageNumber,
        pageSize: this.sortModel.pageSize,
        startEventTimeRange: this.filterModel.startEventTimeFilter ? this.dateSetter(this.filterModel.startEventTimeFilter) : null,
        endEventTimeRange: this.filterModel.endEventTimeFilter ? this.dateSetter(this.filterModel.endEventTimeFilter) : null,
        baseVoltageFilter: this.filterModel.baseVoltageFilter,
        energyObjectFilter: this.filterModel.energyObjectFilter,
        eoRegionFilter: this.filterModel.eoRegionFilter,
        primaryEquipmentFilter: this.filterModel.primaryEquipmentFilter,
        secondEquipmentPanelFilter: this.filterModel.secondEquipmentPanelFilter,
        dCOrganizationFilter: this.filterModel.dcOrganizationFilter,
        technicalOrganizationsFilter: this.filterModel.technicalOrganizationsFilter,
        psrTypeFilter: this.filterModel.psrTypeFilter,
        estimationFilter: this.filterModel.estimationFilter,
        shortCircuitFormFilter: this.filterModel.shortCircuitFormFilter,
        shortCircuitPlaceFilter: this.filterModel.shortCircuitPlaceFilter,
        showDeleted: this.filterModel.showDeleted,
        numberFilter: this.filterModel.numberFilter,
        staffCategoryFilter: this.filterModel.staffCategoryFilter,
        accidentEquipmentFilter: this.filterModel.accidentEquipmentFilter,
        staffCulpabilitiesFilter: this.filterModel.staffCulpabilitiesFilter,
        technicalReasonsFilter: this.filterModel.technicalReasonsFilter,
        secondEquipmentTypesFilter: this.filterModel.secondEquipmentTypesFilter,
        misoperationReasonsFilter: this.filterModel.misoperationReasonsFilter,
        eventSourcesFilter: this.filterModel.eventSourcesFilter,
        eventSourcesFileNameFilter: this.filterModel.eventSourcesFileNameFilter,
        ownerOrganizationsFilter: this.filterModel.ownerOrganizationsFilter,
        producerOrganizationsFilter: this.filterModel.producerOrganizationsFilter,
        cardInformationFilter: this.filterModel.cardInformationFilter,
        executionsFilter: this.filterModel.executionsFilter,
        productAssetModelsFilter: this.filterModel.productAssetModelsFilter,
        registrationGroupFilter: this.filterModel.registrationGroupFilter,
        faultedPhasesFilter: this.filterModel.faultedPhasesFilter,
        bmmnCheckTypesFilter: this.filterModel.bmmnCheckTypesFilter,
        onlyPrimaryEquipments: false,
        sortField: this.sortModel.prop,
        showNeedCorrect: this.filterModel.showNeedCorrect,
        sortDesc: this.sortModel.order === 'descending',
        correlationStatuses: this.filterModel.correlationStatuses,
        timeZoneUser: this.userTimeZone
      };

      // if (this.filterModel.eventDateRange) {
      //   params.startEventTimeRange = this.filterModel.eventDateRange[0] ? this.dateSetter(this.filterModel.eventDateRange[0]) : null;
      //   params.endEventTimeRange = this.filterModel.eventDateRange[1] ? this.dateSetter(this.filterModel.eventDateRange[1]) : null;
      // }

      if (this.userHasRole('IncidentsDoneView')) {
        params.showNeedCorrect = false;
        this.filterModel.showNeedCorrect = false;
      }

      const getAction = this.userHasRoleGroup('ExternalUser')
        ? udEventsApi.getEventsWithPagination
        : eventsApi.getEventsWithPagination;

      // if (this.userHasRoleGroup('ExternalUser')) {
      //   params = {
      //     ...params,
      //     showNeedCorrect: this.filterModel.showNeedCorrect
      //   };
      // }

      const res = await getAction(params);
      if (res.data) {
        this.events = res.data.items;
        this.totalCount = res.data.totalCount;
        this.loading = false;
      } else if (res.code === 'ERR_CANCELED') {
        this.loading = true;
      } else {
        this.loading = false;
      }  
    },
    async handleSizeChange(event) {
      this.sortModel.pageNumber = 1;
      this.sortModel.pageSize = event;
      await this.getEvents();
    },
    async handleCurrentChange(event) {
      this.sortModel.pageNumber = event;
      await this.getEvents();
    },
    async handleSortChange(event) {
      if (event.order === null) {
        this.sortModel.order = null;
        this.sortModel.prop = null;
      } else {
        this.sortModel.order = event.order;
        this.sortModel.prop = event.prop;
      }
    },
    clearFilters() {
      this.filterModel = {
        startEventTimeFilter: '',
        endEventTimeFilter: '',
        eventDateRange: [],
        primaryEquipmentFilter: '',
        secondEquipmentPanelFilter: '',
        baseVoltageFilter: [],
        energyObjectFilter: [],
        eoRegionFilter: [],
        showDeleted: false,
        dcOrganizationFilter: [],
        technicalOrganizationsFilter: [],
        psrTypeFilter: [],
        estimationFilter: [],
        shortCircuitFormFilter: [],
        shortCircuitPlaceFilter: [],
        showNeedCorrect: null,
        numberFilter: '',
        staffCategoryFilter: [],
        accidentEquipmentFilter: [],
        staffCulpabilitiesFilter: [],
        technicalReasonsFilter: [],
        secondEquipmentTypesFilter: [],
        misoperationReasonsFilter: [],
        eventSourcesFilter: '',
        eventSourcesFileNameFilter: [],
        ownerOrganizationsFilter: [],
        producerOrganizationsFilter: [],
        cardInformationFilter: [],
        executionsFilter: [],
        productAssetModelsFilter: [],
        registrationGroupFilter: [],
        faultedPhasesFilter: [],
        bmmnCheckTypesFilter: [],
        correlationStatuses: [],
      };
      if (this.userHasRole('IncidentsDoneView')) {
        this.filterModel.showNeedCorrect = false;
      }
    },
    handleDelete(event) {
      this.$confirm('Вы действительно хотите удалить запись?', 'Подтверждение', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      })
        .then(async () => {
          const deleteAction = this.userHasRoleGroup('ExternalUser')
            ? udEventsApi.delete
            : eventsApi.delete;

          const deleteResult = await deleteAction(event.id);

          if (deleteResult.data.succeeded === false) {
            this.$alert(deleteResult.data.resultMessage, 'Ошибка');
          } else {
            await this.getEvents();
          }
        })
        .catch(() => {
        });
    },
    handleRestore(event) {
      this.$confirm('Вы действительно хотите восстановить запись? При восстановлении события будет восстановлена карточка аварии.', 'Подтверждение', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      })
        .then(async () => {
          const restoreAction = this.userHasRoleGroup('ExternalUser')
            ? udEventsApi.restore
            : eventsApi.restore;

          await restoreAction(event.id);
          await this.getEvents();
        })
        .catch(() => {
        });
    },
    async handleOpenCor(corId) {
      await this.myModalStore.openByIdAsync(corId);
    }
  },
};
</script>

<style scoped>
.el-table:before {
  height: 0px;
}
</style>
