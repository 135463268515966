<template>
  <el-collapse v-model="filter" @change="handleChange">
    <el-collapse-item title="Фильтр" name="p1" class="events_filter-collapse-item">
      <template v-slot:title>
        <span>
          <span>Фильтр</span>
          <el-button class="ml-5"
              plain
              size="mini"
              type="default"
              @click.stop="clearFilters"
              style="font-family: Montserrat-Medium !important;">
            Очистить фильтры
          </el-button>
        </span>
      </template>
      <el-row class="m-0" :gutter="5">
        <el-col :span="6">
          <span class="mr-2" style="min-width:13rem;">Номер события:</span>
          <el-input size="small" v-model="filterModel.numberFilter" clearable
                    id="incidients-filter--number"></el-input>
        </el-col>
        <el-col :span="6">
          <el-row class="m-0">
            <el-col :span="24"><span class="mr-2"
                                      style="min-width:13rem;">Дата и время события:</span></el-col>
            <el-col :span="12">
              <el-date-picker v-model="filterModel.startEventTimeFilter"
                              class="date-editor-padding-off"
                              v-mask="'##.##.#### ##:##'"
                              size="small"
                              style="width: 100%"
                              type="datetime"
                              range-separator="-"
                              placeholder="дд.мм.гггг чч:мм"
                              format="dd.MM.yyyy HH:mm"
                              :picker-options="datePickerOptions">
              </el-date-picker>
            </el-col>
            <el-col :span="1" style="text-align: center">-</el-col>
            <el-col :span="11">
              <el-date-picker v-model="filterModel.endEventTimeFilter"
                              class="date-editor-padding-off"
                              v-mask="'##.##.#### ##:##'"
                              size="small"
                              style="width: 100%"
                              type="datetime"
                              range-separator="-"
                              placeholder="дд.мм.гггг чч:мм"
                              format="dd.MM.yyyy HH:mm"
                              :default-time="'23:59:00'"
                              :picker-options="datePickerOptions">
              </el-date-picker>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6" v-if="showEventsMatch">
          <span class="mr-2" style="min-width:13rem;">Статус сопоставления события:</span>
          <el-select multiple filterable :collapse-tags="filterModel.correlationStatuses.length > 5"
                     clearable v-model="filterModel.correlationStatuses" size="small"
                     popper-class="search-select">
            <el-option
              v-for="item in statusList"
              :key="item.key"
              :label="item.value"
              :value="item.key">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <span style="min-width:13rem;">Корректировка:</span>
          <el-select filterable :disabled="userHasRole('IncidentsDoneView')"
                     clearable v-model="filterModel.showNeedCorrect" size="small">
            <el-option
              v-for="item in needCorrects"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row class="m-0" :gutter="5">
        <el-col :span="6">
          <span class="mr-2" style="min-width:13rem;">Оборудование/ЛЭП события:</span>
          <remote-search-input
            v-model="filterModel.accidentEquipmentFilter"
            searchUrl="/api/AccidentEquipment/SearchByName"
            :allow-create=false
            :multiple=true
            :start-length=3
          />
        </el-col>

        <el-col :span="6">
          <span style="min-width:13rem;">Сработавшее УРЗА:</span>
          <remote-search-input
            v-model="filterModel.secondEquipmentPanelFilter"
            searchUrl="/api/secondEquipmentPanels/SearchByName"
            :allow-create=false
            :multiple=true
            :start-length=3
          />
        </el-col>

        <el-col :span="6">
          <span class="mr-2" style="min-width:13rem;">Технический учет ДЦ:</span>
          <remote-search-input
            v-model="filterModel.technicalOrganizationsFilter"
            searchUrl="/api/organizations/SearchByDC"
            :allow-create=false
            :multiple=true
            :start-length=3
          />
        </el-col>

        <el-col :span="6">
          <span style="min-width:13rem;">Энергосистема:</span>
          <!-- <remote-search-input
            v-model="filterModel.eoRegionFilter"
            searchUrl="/api/regions/SearchByName"
            :allow-create=false
            :multiple="true"
            :start-length=3
          /> -->
          <el-select multiple filterable clearable size="small"
                     ref="eoRegionFilterSelector"
                     :collapse-tags="filterModel.eoRegionFilter.length > 3"
                     v-model="filterModel.eoRegionFilter" 
                     popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.eoRegionFilter = getSelectedIdsByName(operationalZones, $refs.eoRegionFilterSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in operationalZones"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row class="m-0" :gutter="5">
        <el-col :span="6">
          <span style="min-width:13rem;">Энергообъект:</span>
          <remote-search-input
            v-model="filterModel.energyObjectFilter"
            searchUrl="/api/energyObjects/SearchByName"
            :allow-create=false
            :multiple=true
            :start-length=3
          />
        </el-col>
        <el-col :span="6">
          <span style="min-width:13rem;">Владелец объекта электроэнергетики:</span>
          <remote-search-input
            v-model="filterModel.ownerOrganizationsFilter"
            searchUrl="/api/Organizations/SearchByOwner"
            :allow-create=false
            :multiple=true
            :start-length=1
          />
        </el-col>
        <el-col :span="6">
          <span style="min-width:13rem;">Фирменное УРЗА:</span>
          <remote-search-input
            v-model="filterModel.productAssetModelsFilter"
            searchUrl="/api/ProductAssetModels/SearchByName"
            :allow-create=false
            :multiple=true
          />
        </el-col>

        <el-col :span="6">
          <span style="min-width:13rem;">Производитель техники РЗА:</span>
          <remote-search-input
            v-model="filterModel.producerOrganizationsFilter"
            searchUrl="/api/Organizations/SearchByProducer"
            :allow-create=false
            :multiple=true
            :start-length=3
          />
        </el-col>
      </el-row>

      <el-row class="m-0" :gutter="5">
        <el-col :span="4">
          <span class="mr-2" style="min-width:13rem;">Организационная причина:</span>
          <staff-culpability-select multiple filterable clearable size="small" 
                                    :collapse-tags="filterModel.staffCulpabilitiesFilter.length > 5"
                                    v-model="filterModel.staffCulpabilitiesFilter" 
                                    popper-class="search-select" />
        </el-col>

        <el-col :span="4">
          <span style="min-width:13rem;">Техническая причина:</span>
          <technical-reason-select multiple filterable clearable 
                                   :collapse-tags="filterModel.technicalReasonsFilter.length > 5"
                                   v-model="filterModel.technicalReasonsFilter"
                                   popper-class="search-select" />
        </el-col>
        <el-col :span="4">
          <span style="min-width:13rem;">Детализированная причина:</span>
          <el-select multiple filterable clearable size="small"
                     ref="misoperationReasonsSelector"
                     :collapse-tags="filterModel.misoperationReasonsFilter.length > 5"
                     v-model="filterModel.misoperationReasonsFilter" 
                     popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.misoperationReasonsFilter = getSelectedValuesByName(misoperationReasons, $refs.misoperationReasonsSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option v-for="item in misoperationReasons"
                       :key="item.id"
                       :label="item.name"
                       :value="item.name">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <span style="min-width:13rem;">Источник:</span>
          <el-select filterable clearable size="small"
                     ref="misoperationReasonsSelector"
                     v-model="filterModel.eventSourcesFilter"
                     popper-class="search-select"
                     @change="onChange">
            <el-option
              v-for="item in eventSources"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <span style="min-width:13rem;">Наименование файла:</span>
          <remote-search-input v-if="this.userHasRoleGroup('ExternalUser')"
            v-model="filterModel.eventSourcesFileNameFilter"
            ref="eventSourcesFileNameSelector"
            :searchUrl="'/api/UserUpload/ExtEventSearchByName?EventSourceTypeId='+filterModel.eventSourcesFilter+'&timeZoneUser='+userTimeZone"
            :allow-create=false
            :multiple=true
            :start-length=0
          />
          <remote-search-input v-else
            v-model="filterModel.eventSourcesFileNameFilter"
            ref="eventSourcesFileNameSelector"
            :searchUrl="'/api/UserUpload/IntEventSearchByName?EventSourceTypeId='+filterModel.eventSourcesFilter+'&timeZoneUser='+userTimeZone"
            :allow-create=false
            :multiple=true
            :start-length=0
          />
        </el-col>
        <el-col :span="4">
          <span class="mr-2" style="min-width:13rem;">Категория персонала:</span>
          <staff-category-select multiple filterable clearable size="small"
                                  :collapse-tags="filterModel.staffCategoryFilter.length > 5"
                                  v-model="filterModel.staffCategoryFilter"
                                  popper-class="search-select">
          </staff-category-select>
        </el-col>
      </el-row>

      <el-row class="m-0" :gutter="5">
        <el-col :span="4">
          <span class="mr-2" style="min-width:13rem;">Стандартная функция:</span>
          <el-select multiple filterable clearable size="small"
                     ref="psrTypeSelector"
                     :collapse-tags="filterModel.psrTypeFilter.length > 5"
                     v-model="filterModel.psrTypeFilter" 
                     popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.psrTypeFilter = getSelectedValuesByName(secondEquipmentPsrTypes, $refs.psrTypeSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in secondEquipmentPsrTypes"
              :key="item.id"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="4">
          <span style="min-width:13rem;">Исполнение:</span>
          <el-select multiple filterable clearable size="small"
                     ref="executionsSelector"
                     :collapse-tags="filterModel.executionsFilter.length > 5"
                     v-model="filterModel.executionsFilter" 
                     popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.executionsFilter = getSelectedValuesByCode(executions, $refs.executionsSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in executions"
              :key="item.id"
              :label="item.code"
              :value="item.code">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="4">
          <span class="mr-2" style="min-width:13rem;">Вид КЗ:</span>
          <el-select multiple filterable clearable size="small"
                     ref="shortCircuitFormSelector"
                     :collapse-tags="filterModel.shortCircuitFormFilter.length > 5" 
                     v-model="filterModel.shortCircuitFormFilter">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.shortCircuitFormFilter = getSelectedValuesByCode(shortCircuitForms, $refs.shortCircuitFormSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in shortCircuitForms"
              :key="item.id"
              :label="item.code"
              :value="item.code">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="4">
          <span class="mr-2" style="min-width:13rem;">Оценка:</span>
          <el-select multiple filterable clearable size="small"
                     ref="estimationSelector"
                     :collapse-tags="filterModel.estimationFilter.length > 5"
                     v-model="filterModel.estimationFilter">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.estimationFilter = getSelectedValuesByCode(estimations, $refs.estimationSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in estimations"
              :key="item.id"
              :label="item.code"
              :value="item.code">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="4">
          <span class="mr-2" style="min-width:13rem;">Напряжение:</span>
          <el-select multiple filterable clearable size="small"
                     ref="baseVoltageSelector"
                     :collapse-tags="filterModel.baseVoltageFilter.length > 5"
                     v-model="filterModel.baseVoltageFilter">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.baseVoltageFilter = getSelectedIdsByName(sortedDescBaseVoltages, $refs.baseVoltageSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in sortedDescBaseVoltages"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="4">
          <span style="min-width:13rem;">Дополнительная информация:</span>
          <el-select multiple filterable clearable size="small"
                     ref="cardInformationSelector"
                     :collapse-tags="filterModel.cardInformationFilter.length > 5"
                     v-model="filterModel.cardInformationFilter" 
                     popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.cardInformationFilter = getSelectedValuesByName(cardInformation, $refs.cardInformationSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in cardInformation"
              :key="item.id"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="m-0 mb-3" :gutter="5">

        <el-col :span="4">
          <span style="min-width:13rem;">Группа учета:</span>
          <el-select multiple filterable clearable size="small"
                     ref="registrationGroupSelector"
                     :collapse-tags="filterModel.registrationGroupFilter.length > 5"
                     v-model="filterModel.registrationGroupFilter">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.registrationGroupFilter = getSelectedValuesByName(registrationGroups, $refs.registrationGroupSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in registrationGroups"
              :key="item.id"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="4">
          <span style="min-width:13rem;">Повреждённые фазы:</span>
          <el-select multiple filterable clearable size="small"
                     ref="faultedPhasesSelector"
                     :collapse-tags="filterModel.faultedPhasesFilter.length > 5"
                      v-model="filterModel.faultedPhasesFilter">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.faultedPhasesFilter = getSelectedValuesByName(faultedPhases, $refs.faultedPhasesSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in faultedPhases"
              :key="item.id"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="4">
          <span class="mr-2" style="min-width:13rem;">Место КЗ:</span>
          <el-select multiple filterable clearable size="small"
                     ref="shortCircuitPlaceSelector"
                     :collapse-tags="filterModel.shortCircuitPlaceFilter.length > 5"
                     v-model="filterModel.shortCircuitPlaceFilter">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.shortCircuitPlaceFilter = getSelectedValuesByCode(shortCircuitPlaces, $refs.shortCircuitPlaceSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in shortCircuitPlaces"
              :key="item.id"
              :label="item.code"
              :value="item.code">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="4">
          <span style="min-width:13rem;">Проверка БММС:</span>
          <el-select multiple filterable clearable size="small"
                     ref="bmmnCheckTypesSelector"
                     :collapse-tags="filterModel.bmmnCheckTypesFilter.length > 5"
                     v-model="filterModel.bmmnCheckTypesFilter">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.bmmnCheckTypesFilter = getSelectedValuesByName(bmmnCheckTypes, $refs.bmmnCheckTypesSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in bmmnCheckTypes"
              :key="item.id"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="4">
          <span style="min-width:13rem;">Сработавший КРЗА:</span>
          <remote-search-input
            v-model="filterModel.secondEquipmentTypesFilter"
            searchUrl="/api/secondEquipmentTypes/SearchByName"
            :allow-create=false
            :multiple=true
            :start-length=3
          />
        </el-col>
        <el-col :span="4">
          <el-row class="m-0">
            <el-col><span class="mr-2" style="min-width:5rem;">Отображать только удаленные</span>
            </el-col>
            <el-col>
              <el-switch size="small" v-model="filterModel.showDeleted"></el-switch>
            </el-col>
          </el-row>
        </el-col>

      </el-row>
      <!-- <el-row class="m-0 filter">
        <el-col :span="8" class="ml-2 filter--container">
          <el-button @click="clearFilters" type="text"
                     style="font-family: Montserrat-Medium !important;">
            Очистить фильтры
          </el-button>
        </el-col>
      </el-row> -->
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { mapGetters } from 'vuex';
import dateHelpers from '@/mixins/dateHelpers';
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';
import UserSettingsService from '@/service/userSettingsService';
import misoperationReasons from '@/api/nsi/misoperationReasons';
import cardInformation from '@/api/nsi/cardInformation';
import executions from '@/api/nsi/executions';
// import userUploadApi from '@/api/eventsAndIncidents/userUpload';
import TechnicalReasonSelect from '@/components/Ui/TechnicalReason/TechnicalReasonSelect.vue';
import StaffCulpabilitySelect from '@/components/Ui/StaffCulpability/StaffCulpabilitySelect.vue';
import StaffCategorySelect from '@/components/Ui/StaffCategory/StaffCategorySelect.vue';
import EventCardCorrelationRepository from '@/repositories/EventCardCorrelationRepository';
import filterHelper from '@/mixins/filterHelper';

export default {
  name: 'EventsFilter',
  mixins: [dateHelpers, filterHelper],
  props: {
    filterModel: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    StaffCategorySelect, 
    StaffCulpabilitySelect, 
    TechnicalReasonSelect, 
    RemoteSearchInput
  },
  data() {
    return {
      filter: [''],
      misoperationReasons: [],
      cardInformation: [],
      executions: [],
      statusList: [],
      // registrationGroupList: [],
      needCorrects: [
        { id: null, name: 'Выбрать все' },
        { id: true, name: 'Требующие корректировки' },
        { id: false, name: 'Не требующие корректировки' },
      ]
    };
  },
  async created() {
    if (UserSettingsService.has('eventsFilter_filter_open', this.user.id)) {
      this.filter = ['p1'];
    }
    const misopReasons = await misoperationReasons.getMisoperationReasonsWithPagination(1, 1000000, null, 'name', false);
    if (misopReasons.data) {
      this.misoperationReasons = misopReasons.data.items;
    }
    const cardInfo = await cardInformation.getCardInformationWithPagination(1, 1000000, null, 'name', false);
    if (cardInfo.data) {
      this.cardInformation = cardInfo.data.items;
    }
    const exec = await executions.getExecutionsWithPagination(1, 1000000, null, 'name', false);
    if (exec.data) {
      this.executions = exec.data.items;
    }
    this.statusList = await EventCardCorrelationRepository.getCorrelationStatusesAsync();
    
    await this.checkRoute(this.$route);
  },
  computed: {
    ...mapGetters('dictionaries', ['baseVoltages',
      'estimations',
      'shortCircuitForms',
      'shortCircuitPlaces',
      'energyObjectPsrTypes',
      'secondEquipmentPsrTypes',
      'registrationGroups',
      'faultedPhases',
      'bmmnCheckTypes',
      'operationalZones',
      'eventSources'
    ]),
    ...mapGetters('identity', ['userHasRole', 'userHasRoleGroup', 'user', 'userTimeZone']),
    showEventsMatch() {
      return this.userHasRole('EventsMatchExternal');
    },
    sortedDescBaseVoltages() {
      const baVoWithDefault = [...this.baseVoltages, { id: '', name: 'Без напряжения', code: 0 }];
      return baVoWithDefault.sort((a, b) => b.code - a.code);
    }
  },
  watch: {
    $route(newVal) {
      if (newVal.query && newVal.query.waitShow) {
        this.checkRoute(this.$route);
      }
    },
    // 'filterModel.eventSourcesFilter': {
    //   handler() {
    //      this.$refs.eventSourcesFileNameSelector.$refs.select.previousQuery = ' ';
    //     this.filterModel.eventSourcesFileNameFilter = [];
    //   },
    //   deep: true
    // }
    // registrationGroups(newValue) {
    //   this.registrationGroupList = newValue.filter((el) => el.id !== 100 && el.id !== 101);
    // },
  },
  methods: {
    async onChange() {
        this.$refs.eventSourcesFileNameSelector.$refs.select.previousQuery = ' ';
        this.filterModel.eventSourcesFileNameFilter = [];
    },
    handleChange(val) {
      if (val.find((el) => el === 'p1')) {
        UserSettingsService.set('eventsFilter_filter_open', true, this.user.id);
      } else {
        UserSettingsService.delete('eventsFilter_filter_open', this.user.id);
      }
      const objectLength = Object.keys(val);
      if (objectLength && objectLength.length > 0) {
        // this.filterModel.isActive = true;
        this.$emit('active-change', true);
      } else {
        // this.filterModel.isActive = false;
        this.$emit('active-change', false);
      }
    },
    clearFilters() {
      this.$emit('clear-filters');
    },
    async checkRoute(route) {
      if (route.query && route.query.fileName) {
        await this.clearFilters();
        await this.$nextTick(() => {
          this.filterModel.eventSourcesFileNameFilter = [route.query.fileName];
          this.$router.replace({ name: 'Events' });
        });
        this.$emit('loadData');
      } else if (route.query && route.query.waitShow) {
        await this.clearFilters();
        this.filterModel.correlationStatuses = this.userHasRoleGroup('ExternalUser') ? [2] : [1];
        console.log(this.user);
        this.filterModel.technicalOrganizationsFilter = this.userHasRoleGroup('ExternalUser') || this.userHasRoleGroup('LevelIa') ? [] : [this.user.organizationName];
        this.filterModel.showDeleted = false;
        this.$nextTick(() => {
          this.$router.replace({ name: 'Events' });
        });
        this.$emit('loadData');
      } 
    }
  }
};
</script>

<style scoped lang="scss">

.events_filter-collapse-item :deep(.el-collapse-item__content) {
  padding-bottom: 0 !important;
}

</style>
