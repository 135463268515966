<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false" >
      <div slot="title">
        <span class="mr-2">Комплекс РЗА</span>
        <template v-if="editMode">
          <el-button @click="saveSecondEquipmentTypeCard()" :disabled="loading" class="back_action_panel--first_element" type="text">
            <SaveIcon />Сохранить
          </el-button>
          <el-button @click="closeModal" type="text">
            <CancelIcon />Отменить
          </el-button>
        </template>
      </div>
      <el-form :rules="this.rules" v-loading="this.loading" ref="secondEquipmentTypeCardForm" :model="secondEquipmentTypeCard" label-position="top">
        <el-container>
          <el-form-item prop="secondEquipmentTypeName" label="Комплекс РЗА:">
            <el-input size="small" disabled v-model="secondEquipmentTypeCard.secondEquipmentTypeName"></el-input>
          </el-form-item>

          <el-form-item prop="estimationId" label="Оценка действия:" class="estimation">
            <el-select :disabled="!editMode" popper-class="search-select" size="small" v-model="secondEquipmentTypeCard.estimationId" placeholder="">
              <el-option
                v-for="item in estimations"
                :key="item.id"
                :label="item.code + ' (' + item.name + ')'"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <div>
            <label class="el-form-item__label">Технические причины:</label>
            <el-button v-if="editMode" class="ml-2" type="primary" size="mini" @click="showTechnicalReasonModal" >
              Выбрать
            </el-button>
          </div>
          <el-form-item>
          <div class="selected-tags">
              <el-tag v-for="tag in secondEquipmentTypeCard.technicalReasons"
                      :key="tag.id"
                      :closable="false"
                      size="small"
                      class="mr-1 mb-1">
                {{tag.code}}
              </el-tag>
          </div>
          </el-form-item>

          <el-form-item prop="misoperationReasons" label="Детализированная причина неправильной работы:">
            <el-select :disabled="!editMode" popper-class="search-select" size="small" v-model="secondEquipmentTypeCard.misoperationReasons" multiple placeholder="">
              <el-option
                v-for="item in misoperationReasons"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="organizationalReasons">
            <el-row class="mt-0">
              <label class="el-form-item__label">
                Организационные причины:
              </label>
                <el-button
                  v-if="editMode"
                  circle
                  icon="el-icon-plus"
                  size="small"
                  class="p-1 mb-1 ml-2"
                  @click="addNewRow">
                </el-button>
            </el-row>
            <el-col :span="24">
              <el-table
                :data="this.secondEquipmentTypeCard.organizationalReasons"
                v-loading="this.loading"
                size="small"
                :default-sort = "{prop: 'code', order: 'ascending'}"
                style="width: 100%"
              >
                <el-table-column prop="code" label="Код причины">
                  <template slot-scope="props">
                    <el-select :disabled="!editMode" size="small" popper-class="search-select" v-model="props.row.code" placeholder="Выберете код причины">
                      <template v-for="group in staffCulpabilities">
                        <el-option-group
                          v-if="group.children.length > 0"
                          :key="group.id"
                          :label="group.name">
                            <el-option
                              v-for="item in group.children"
                              :key="item.id"
                              :label="item.code + ' (' + item.name + ')'"
                              :value="item.code">
                            </el-option>
                        </el-option-group>
                        <el-option
                            v-else
                            :key="group.id"
                            :label="group.code + ' (' + group.name + ')'"
                            :value="group.code">
                        </el-option>
                      </template>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="category" label="Категория персонала">
                  <template slot-scope="props">
                    <el-select :disabled="!editMode" size="small" popper-class="search-select" v-model="props.row.category" placeholder="Выберете категорию">
                        <el-option
                          v-for="item in staffCategories"
                          :key="item.id"
                          :label="item.code + ' (' + item.name + ')'"
                          :value="item.id">
                        </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="organization" label="Организация">
                  <template slot-scope="scope">
                      <remote-search-input
                        v-model="scope.row.organization"
                        :key="scope.row.key"
                        :disabled="!editMode"
                        searchUrl="/api/organizations/SearchByName"
                        size="small"
                        return-prop="id"
                        placeholder="Выберите организацию"
                        :show-when-init="true"
                        :initial-value="scope.row.organizationName"
                        :start-length=1
                        :allow-create="false"
                        @label-update="scope.row.organizationName = $event"
                      />
                  </template>
                </el-table-column>
                <el-table-column width="42px">
                  <template slot-scope="scope">
                    <el-button v-if="editMode" @click="deleteRow(scope.$index)" type="text" icon="el-icon-delete"></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-form-item>
        </el-container>
      </el-form>
    </el-dialog>

    <select-technical-reasons-modal
      :key="key"
      v-model="selectTechnicalReasonModalVisible"
      :tree="technicalReasons"
      :selected-items="secondEquipmentTypeCard.technicalReasons"
      @save="confirmSelectedTechnicalReasons($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';
import SaveIcon from '@/assets/icons/save.svg';
import CancelIcon from '@/assets/icons/cancel.svg';
import formatters from '@/mixins/formatters.js';
import dateHelpers from '@/mixins/dateHelpers';
import secondEquipmentTypeCardsApi from '@/api/eventsAndIncidents/secondEquipmentTypeCards';
import SelectTechnicalReasonsModal from '@/views/EventsAndIncidents/Events/Modals/SelectTechnicalReasonsModal';

export default {
  name: 'SecondEquipmentTypeCardModal',
  props: {
    value: { type: Boolean, default: () => false },
    id: { type: String, default: () => '' },
    eventCard: { type: Object, default: () => {} },
    secondEquipmentTypeCard: { type: Object, default: () => {} },
    editMode: { type: Boolean, default: () => false },
  },
  mixins: [formatters, dateHelpers],
  components: {
      SaveIcon,
      CancelIcon,
      RemoteSearchInput,
      SelectTechnicalReasonsModal
  },
  computed: {
    ...mapGetters('dictionaries', ['cardInformations', 'misoperationReasons', 'estimations', 'technicalReasons', 'staffCategories', 'staffCulpabilities']),
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  created() {
    if (this.secondEquipmentTypeCard && this.secondEquipmentTypeCard.organizationalReasons) {
      this.secondEquipmentTypeCard.organizationalReasons.forEach((f) => {
        f.key = this.newRandomKey();
      });
    }
  },
  data() {
    const estimationValidation = (rule, value, callback) => {
    const filteredEstimations = this.estimations.filter((x) => x.code === 'ПУ1'
                                                                                      || x.code === 'ПУ2'
                                                                                      || x.code === 'П'
                                                                                      || x.code === 'ПУ'
                                                                                      || x.code === 'ПН'
                                                                                      || x.code === 'П сигнал');

      if (filteredEstimations.some((x) => x.id === this.secondEquipmentTypeCard.estimationId)
          && (this.secondEquipmentTypeCard.technicalReasons.length > 0 || this.secondEquipmentTypeCard.organizationalReasons.length > 0 || this.secondEquipmentTypeCard.misoperationReasons.length > 0)) {
        return callback(new Error('Нельзя выбрать данную оценку, так как заполнены технические, организационные или детализированные причины неправильной работы'));
      } else {
        return callback();
      }
    };
    const orgReasonsValidation = (rule, value, callback) => {
      const reasons = this.secondEquipmentTypeCard.organizationalReasons;

      const duplicate = this.searchDuplicateOrgReasons(reasons);
      const missed = this.searchMissedFieldsOrgReasons(reasons);
      if (duplicate) {
        return callback(new Error('Указаны две или более одинаковые организационные причины'));
      } else if (missed) {
        return callback(new Error('Все поля обязательны для заполнения'));
      } else {
        return callback();
      }
    };
    return {
      key: 0,
      loading: false,
      selectTechnicalReasonModalVisible: false,
      selectTechnicalReasons: [],
      rules: {
        estimationId: [
          { validator: estimationValidation, trigger: 'blur', required: false }
        ],
        organizationalReasons: [
          { validator: orgReasonsValidation, trigger: 'blur', required: false }
        ]
      },
    };
  },
  methods: {
    async saveSecondEquipmentTypeCard() {
      const valid = await this.validateForm();
      if (!valid) return;

      this.loading = true;
      this.secondEquipmentTypeCard.technicalReasons = this.secondEquipmentTypeCard.technicalReasons.map((i) => i.id);
      await secondEquipmentTypeCardsApi.updateSecondEquipmentTypeCard(this.secondEquipmentTypeCard).then((response) => {
          this.dialogVisible = false;
          this.$emit('update', response);
          this.loading = false;
      });
    },
    async validateForm() {
      const valid = await this.$refs.secondEquipmentTypeCardForm.validate();
      return valid;
    },
    searchDuplicateOrgReasons(array) {
      // eslint-disable-next-line no-shadow
      return array.map((value) => value.code + value.category + value.organization).some((value, index, array) => array.indexOf(value) !== array.lastIndexOf(value));
    },
    searchMissedFieldsOrgReasons(array) {
      let result = false;
      // eslint-disable-next-line func-names
      // eslint-disable-next-line consistent-return
      array.forEach((x) => {
        if (x.organization === null || x.organization === '' || x.category === null || x.category === '' || x.category === null || x.category === '') {
          result = true;
        }
      });
      return result;
    },
    addNewRow() {
      this.secondEquipmentTypeCard.organizationalReasons.unshift({
        key: this.newRandomKey(),
        code: '',
        category: '',
        organization: ''
      });
    },
    deleteRow(idx) {
      this.$delete(this.secondEquipmentTypeCard.organizationalReasons, idx);
    },
    showTechnicalReasonModal() {
      this.selectTechnicalReasons = this.secondEquipmentTypeCard.technicalReasons;
      this.key += 1;
      this.selectTechnicalReasonModalVisible = true;
    },

    confirmSelectedTechnicalReasons(event) {
      if (event) {
        this.secondEquipmentTypeCard.technicalReasons = event;
        this.selectTechnicalReasonModalVisible = false;
      }
    },
    closeModal() {
      this.dialogVisible = false;
      this.$refs.secondEquipmentTypeCardForm.clearValidate();
    },
    newRandomKey() {
      return new Date().getTime() + Math.floor(Math.random() * 10000);
    }
  },
};

</script>

<style scoped lang="scss">
svg {
  margin-right: .3rem;
  vertical-align: bottom;
}
.el-form{
     display: flex;
     align-content: space-between;
  }

.el-container{
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}
.el-form-item {
  display: flex;
  flex-direction: column;
  width:100%;
  margin-bottom: 1rem;
  margin-bottom: 0px;
    button {
        margin: auto;
    }
}
.el-form-item__error{
    width:100%;
    position: inherit !important;
}
</style>
<style lang="scss">
.estimation {
  .el-form-item__error{
    width:100%;
    position: inherit;
  }
}
.estimation.is-error {
    .el-form-item__content{
      flex-direction: column;
    }
}
</style>
