<template>
  <el-dialog
    :visible.sync="modalVisible"
    title="Устройство РЗА"
    width="80%"
    class="select_primary_equipment--modal"
    :close-on-click-modal="false"
  >
    <div class="my-flex-container-column">
      <select-second-equipment-panel-filter 
        @active-change="filterActive = $event" 
        :filter-model="filterModel" 
        @clear-filters="clearFilters" />

      <div style="text-align: center">
          <pagination
            style="text-align: center"
            :total-count="totalCount"
            :page-size.sync="pageSize"
            :current-page.sync="pageNumber"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
      </div>
      <div>
        <el-button @click="toggleAllNodes" type="text" class="ml-2">Свернуть/развернуть всё</el-button>
        <span v-if="!this.filterModel.skipEoObject" class="total_items">Всего: {{this.secondEquipmentPanelsCount}}</span>
      </div>
      <div v-loading="loading" class="my-flex-data-content">
        <infinite-tree
          ref="tree"
          :data="secondEquipmentPanels"
          :style="{ height: '100%'}"
          @on-check-click="handleCheck"
          @on-open-node="applyChecking"
        >
          <div class="infinite-tree-node" slot-scope="{ node }">{{ node.color }}</div>
        </infinite-tree>
        <!-- <div>
          <el-button @click="this.elTreeCollapse" type="text" class="ml-2">Свернуть/развернуть всё</el-button>
          <span v-if="!this.filterModel.skipEoObject" class="total_items">Всего: {{this.secondEquipmentPanelsCount}}</span>
        </div>
          <el-container class="tree-container mb-3">
            <div class="scrollableTree event-add--tree">
              <el-radio-group v-model="radioGroupModel">
                  <el-tree
                    :data="secondEquipmentPanels"
                    v-loading="loading"
                    node-key="id"
                    ref="tree"
                    default-expand-all
                  >
                      <span class="custom-tree-node" slot-scope="{ node }">
                          <div class="tdWraper">
                              <el-radio v-if="node.level == 2" :label="node.data" @change="handleRadioChange">
                                  <span>
                                      <ShieldIcon />
                                  </span>
                                  <span>{{ node.data.name }}</span>
                              </el-radio>
                              <div v-if="node.level == 1 " class="tree-item">
                                  <span>
                                      <FactoryIcon />
                                  </span>
                                  <span class="ml-2">{{ node.data.name }}</span>
                              </div>
                          </div>
                      </span>
                  </el-tree>
              </el-radio-group>
            </div>
          </el-container> -->
      </div>
      <div class="dialog-footer">
        <el-button type="primary" @click="accept">Применить</el-button>
        <el-button type="default" @click="cancel">Отменить</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>

import { mapGetters } from 'vuex';
import dateHelpers from '@/mixins/dateHelpers';
import pagination from '@/components/Ui/Pagination';
import secondEquipmentPanelsApi from '@/api/eventsAndIncidents/secondEquipmentPanels';
import infiniteTree from '@/components/Ui/InfiniteTree/InfiniteTree';
import UserSettingsService from '@/service/userSettingsService';
import SelectSecondEquipmentPanelFilter from '@/views/Global/SecondEquipmentPanels/SecondEquipmentPanelsFilter.vue';

export default {
  name: 'SelectSecondEquipmentPanelModal',
  components: {
      pagination,
      infiniteTree,
      SelectSecondEquipmentPanelFilter
  },
  mixins: [dateHelpers],
  props: {
      secondEquipmentPanelModalVisible: { type: Boolean, default: () => false },
  },
  watch: {
    filterModel: {
        handler() {
          this.getSecondEquipmentPanels();
        },
        deep: true
      },
  },
  data() {
    return {
        pageNumber: 1,
        pageSize: 10,
        totalCount: 0,
        sortField: 'name',
        sortDesc: false,
        loading: false,
        filter: ['1'],
        filterModel: {
          nameFilter: '',
          eoIdFilter: '',
          skipEoObject: false,
          isLocalNsiFilter: false,
          productAssetModelIdFilter: '',
          duOrganizationName: '',
          dvOrganizationName: '',
          baseVoltageFilter: '',
          eoRegionFilter: [],
          ownerOrganizationsFilter: [],
          registrationGroupFilter: [],
          executionsFilter: [],
          techAdministrationOrganizationsFilter: [],
          techManagementOrganizationsFilter: [],
          technicalOrganizationsFilter: [],
          startCommissioningTimeRange: '',
          endCommissioningTimeRange: '',
          startDecommissioningTimeRange: '',
          endDecommissioningTimeRange: '',
          showDecommissionedSE: null
        },
        secondEquipmentPanels: [],

        selectedSecondEquipmentPanel: {},
        secondEquipmentPanelsCount: 0
    };
    },
    computed: {
      ...mapGetters('identity', ['user']),
      modalVisible: {
        get() {
          return this.secondEquipmentPanelModalVisible;
        },
        set(val) {
          if (!val) {
            this.cancel();
          }
        },
      },
      treeContainer() {
          return this.$refs.tree;
      },
      tableSizeConstant() {
        if (!this.filterActive) {
          return 140;
        }

        return 120;
      },
    },

  async created() {
    if (UserSettingsService.has('SelectSecondEquipmentPanelModal_filter_open', this.user.id)) {
      this.filter = ['p1'];
    }
      await this.getSecondEquipmentPanels();
  },
  methods: {
    async getSecondEquipmentPanels() {
      this.loading = true;
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        nameFilter: this.filterModel.nameFilter,
        eoIdFilter: this.filterModel.eoIdFilter,
        productAssetModelIdFilter: this.filterModel.productAssetModelIdFilter,
        skipEoObject: this.filterModel.skipEoObject,
        isLocalNsiFilter: this.filterModel.isLocalNsiFilter,
        duOrganizationName: this.filterModel.duOrganizationName,
        dvOrganizationName: this.filterModel.dvOrganizationName,
        baseVoltageFilter: this.filterModel.baseVoltageFilter,
        sortField: this.sortField,
        sortDesc: this.sortDesc,
        eoRegionFilter: this.filterModel.eoRegionFilter,
        ownerOrganizationsFilter: this.filterModel.ownerOrganizationsFilter,
        registrationGroupFilter: this.filterModel.registrationGroupFilter,
        executionsFilter: this.filterModel.executionsFilter,  
        techAdministrationOrganizationsFilter: this.filterModel.techAdministrationOrganizationsFilter,
        techManagementOrganizationsFilter: this.filterModel.techManagementOrganizationsFilter,
        technicalOrganizationsFilter: this.filterModel.technicalOrganizationsFilter,
        startCommissioningTimeRange: this.filterModel.periodCommissioningTimeRange?.length > 0 ? this.toUserDate(this.filterModel.periodCommissioningTimeRange[0], 'YYYY-MM-DD') : null,
        endCommissioningTimeRange: this.filterModel.periodCommissioningTimeRange?.length > 0 ? this.toUserDate(this.filterModel.periodCommissioningTimeRange[1], 'YYYY-MM-DD') : null,
        startDecommissioningTimeRange: this.filterModel.periodDecommissioningTimeRange?.length > 0 ? this.toUserDate(this.filterModel.periodDecommissioningTimeRange[0], 'YYYY-MM-DD') : null,
        endDecommissioningTimeRange: this.filterModel.periodDecommissioningTimeRange?.length > 0 ? this.toUserDate(this.filterModel.periodDecommissioningTimeRange[1], 'YYYY-MM-DD') : null,
        showDecommissionedSE: this.filterModel.showDecommissionedSE,
        incidentId: this.incidentId
      };
      const res = await secondEquipmentPanelsApi.getSecondEquipmentPanels(params);
      if (res.data) {
        this.secondEquipmentPanels = res.data.items;
        this.totalCount = res.data.totalCount;
        this.secondEquipmentPanelsCount = res.data.treeItemsCount;
        this.treeContainer.tree.loadData(this.secondEquipmentPanels);
        this.applyChecking();
      }
      this.loading = false;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getSecondEquipmentPanels();
    },
    async handleCurrentChange() {
      await this.getSecondEquipmentPanels();
    },
    async handleChangeFilter(val) {
      if (val.find((el) => el === 'p1')) {
        UserSettingsService.set('SelectSecondEquipmentPanelModal_filter_open', true, this.user.id);
      } else {
        UserSettingsService.delete('SelectSecondEquipmentPanelModal_filter_open', this.user.id);
      }
      const objectLength = Object.keys(val);
      if (objectLength.length > 0) {
        this.filterActive = true;
       } else {
         this.filterActive = false;
      }
    },
    applyChecking() {
      const { nodes } = this.treeContainer.tree;
      nodes.forEach((node) => {
        if (node.nodeType === 'secondEquipmentPanel') {
          node.сheckAllowed = true;
          node.viewAllowed = false;
          node.parent.сheckAllowed = false;
        } else {
          node.сheckAllowed = false;
          node.viewAllowed = false;
        }
      });

      this.treeContainer.rerender();
    },
    clearFilters() {
      this.filterModel = {
        nameFilter: '',
        eoIdFilter: '',
        skipEoObject: false,
        isLocalNsiFilter: false,
        productAssetModelIdFilter: '',
        duOrganizationName: '',
        dvOrganizationName: '',
        baseVoltageFilter: '',
        eoRegionFilter: [],
        ownerOrganizationsFilter: [],
        registrationGroupFilter: [],
        executionsFilter: [],
        techAdministrationOrganizationsFilter: [],
        techManagementOrganizationsFilter: [],
        technicalOrganizationsFilter: [],
        startCommissioningTimeRange: '',
        endCommissioningTimeRange: '',
        startDecommissioningTimeRange: '',
        endDecommissioningTimeRange: '',
        showDecommissionedSE: null
      };
    },
    handleCheck(node) {
      if (node.state.checked && node.nodeType === 'secondEquipmentPanel') {
        this.selectedSecondEquipmentPanel = node;
        this.treeContainer.tree.nodes.forEach((n) => {
          n.state.checked = false;
        });
        const nodes = this.treeContainer.tree.nodes.filter((t) => t.nodeType === 'secondEquipmentPanel' && t.id === node.id);
        if (nodes && nodes.length > 0) {
          nodes.forEach((n) => {
            n.state.checked = true;
          });

          this.treeContainer.rerender();
        }
      } else {
        if (this.selectedSecondEquipmentPanel.id === node.id) {
          this.selectedSecondEquipmentPanel = {};

          const nodes = this.treeContainer.tree.nodes.filter((t) => t.nodeType === 'secondEquipmentPanel' && t.id === node.id);
          if (nodes && nodes.length > 0) {
            nodes.forEach((n) => {
              n.state.checked = false;
            });

            this.treeContainer.rerender();
          }
        }
      }
      this.$emit('on-update', this.selectedSecondEquipmentPanel);
    },
    // handleRadioChange(item) {
    //     this.change = true;
    //     this.selectedSecondEquipmentPanel = item;
    // },
    cancel() {
      this.$emit('cancel');
    },
    accept() {
      this.$emit('accept', this.selectedSecondEquipmentPanel);
    },
    toggleAllNodes() {
      this.$refs.tree.toggleNodes();
      this.applyChecking();
    },
  }
};
</script>

<style scoped lang="scss">
  .el-dialog__wrapper.el-dialog.el-dialog__body{
    padding: 20px;
  }
  .el-radio-group {
      font-size: initial !important;
  }
  .el-radio-group {
    width: 100%;
  }
  span.el-radio__label {
    padding-left: 5px;
  }
  span.el-radio__label>span {
    padding: 0px 5px;
  }
  span.el-radio__inner {
    margin: 0px 0px 9px 0px;
  }
  .el-tabs__header.is-top {
    margin: 0px !important;
  }
  button.el-button.el-button--text:first-child {
    margin-left: 0px;
  }
  .el-col>span {
    font-size: 12px;
  }
  .tree-item > span {
    font-size: 14px;
  }
  /*button.el-button.el-button--text:hover {
    color: #3078bf;
  }*/
  .el-select {
    width: 100%;
  }
  span {
   // white-space: nowrap;
    vertical-align: middle;
  }
  .dialog-footer {
    text-align: center;
  }
.tree-container {
  overflow-y: auto;
  max-height: 40vh;
  min-height: 40vh;
  overflow-x: hidden;
}
  .tree-item>span>i {
    margin: 0px 5px 0px 0px;
  }
  .event-add--tree{
    font-size: 12px;
    .node__content {
      height: auto;
    }
    .custom-tree-node{
      .tree-item > span {
      white-space: normal;
      }
    }
  }
.total_items {
   float: right;
   color: #909399;
   font-family: Montserrat-Regular;
   font-size: 14px;
   margin-top: 12px;
   margin-right: 8px;
}
</style>
