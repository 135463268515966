<template>
  <div class="event_function_rza">
    <el-dialog :visible.sync="visibleModal" width="90%" class="event_function_rza-dialog" :close-on-click-modal="false" append-to-body>
      <div slot="title">
        <span class="mr-2">Функция РЗА</span>
        <el-button v-if="editFunctionCard" :disabled="loading"
                   class="back_action_panel--first_element custom-button" @click="saveFunction"
                   type="text">
          <SaveIcon/>
          Сохранить
        </el-button>
        <el-button v-if="editFunctionCard" class="custom-button" @click="visibleModal = false"
                   type="text">
          <CancelIcon/>
          Отменить
        </el-button>
      </div>

      <el-form :rules="rules" v-loading="loading" ref="functionCardForm" :model="functionCard">
        <el-container style="margin: 0 10px">
          <el-form-item v-if="eventCard.primaryEquipmentId" prop="name"
                        label="Первичное оборудование/ЛЭП:">
            <el-input size="small" disabled v-model="functionCard.primaryEquipmentName"></el-input>
          </el-form-item>
          <el-form-item v-if="eventCard.secondEquipmentPanelId" prop="name" label="Устройство РЗА:">
            <el-input size="small" disabled
                      v-model="functionCard.secondEquipmentPanelName"></el-input>
          </el-form-item>
          <el-form-item label="Фирменное устройство РЗА:">
            <div v-if="this.functionCard.secondEquipmentCardFactoryNames.length >= 1"
                 class="selected-tags">
              <el-tag
                v-for="tag in this.functionCard.secondEquipmentCardFactoryNames"
                :key="tag"
                :closable="false"
                size="small"
                @close="handleSecondEquipmentDelete(tag)"
                class="mr-1 mb-1">
                {{ tag }}
              </el-tag>
            </div>
            <!-- <el-input v-else size="small" disabled v-model="secondEquipmentCardFactoryNames[0].name"></el-input> -->
          </el-form-item>
          <el-form-item prop="secondEquipmentName" label="Функция устройства РЗА:"
                        style="display: inline;">
            <div class="ml-2">
              <el-button v-if="editFunctionCard" type="primary" size="mini"
                         @click="showSelectFunctionModal">Выбрать
              </el-button>
            </div>
          </el-form-item>
          <div v-if="this.functionCard.secondEquipmentsInit" class="selected-tags mt-1">
            <el-tag
              v-for="tag in this.functionCard.secondEquipmentsInit"
              :key="tag.id"
              :closable="false"
              size="small"
              @close="handleSecondEquipmentDelete(tag)"
              class="mr-1 mb-1">
              {{ tag.name }}
            </el-tag>
          </div>
          <el-form-item prop="estimationId" label="Оценка действия:" class="estimation">
            <el-select :disabled="!editFunctionCard" size="small"
                       v-model="functionCard.estimationId"  placeholder=""
                       id="estimation--selector" popper-class="estimation--selector-popper"
            >
              <el-option v-for="item in estimations"
                         :key="item.id"
                         :label="item.code + ' (' + item.name + ')'"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Технические причины:"
                        style="display: inline; "
                        prop="technicalReasons">
            <div class="ml-2">
              <el-button v-if="editFunctionCard" type="primary" size="mini"
                         @click="showSelectTechnicalReasonModal">
                Выбрать
              </el-button>
            </div>
          </el-form-item>
          <div class="selected-tags mt-1">
            <el-tag v-for="tag in functionCard.technicalReasons"
                    :key="tag.id"
                    :closable="false"
                    size="small"
                    class="mr-1 mb-1">
              {{ tag.code }}
            </el-tag>
          </div>
          <el-form-item label="Дополнительная информация:" class="mt-3">
            <el-select :disabled="!editFunctionCard" multiple v-model="functionCard.cardInformation"
                       size="large" placeholder="">
              <el-option
                v-for="item in cardInformations"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :disabled="item.isDisabled"
              />
            </el-select>
          </el-form-item>
          <el-row class="mt-0">
            <el-col :span="10" style="margin-right: 5%;" class="unique_line_height">
              <el-form-item label="Объем упр. воздействий, МВт:">
                <el-input-number :disabled="!editFunctionCard" size="small" :min="0" :precision="2"
                                 :step="0.1"
                                 v-model="functionCard.controlActionCapacity"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
        </el-container>
        <el-container style="width:55%;margin: 0 10px">
          <el-form-item label="Детализированная причина неправильной работы:">
            <el-select :disabled="!editFunctionCard" popper-class="search-select" size="small"
                       v-model="functionCard.misoperationReasons" multiple placeholder="">
              <el-option
                v-for="item in misoperationReasons"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Описание причины:">
            <el-input :disabled="!editFunctionCard" type="textarea"
                      v-model="functionCard.description" rows="5" maxlength="1000"></el-input>
          </el-form-item>
          <el-form-item prop="organizationalReasons" class="organization_reasons">
            <el-row class="mt-1">
              <label class="el-form-item__label">Организационные причины: <span v-if="organizationalReasonsRequired" style="color: #f56c6c">*</span></label>
              <el-button circle
                         icon="el-icon-plus"
                         size="small"
                         class="p-1 mb-1 ml-2"
                         @click="addNewRow"
                         v-if="editFunctionCard">
              </el-button>
            </el-row>
            <el-row class="mt-0">
              <el-table :data="this.functionCard.organizationalReasons"
                        v-loading="loading"
                        size="small"
                        :default-sort="{prop: 'code', order: 'ascending'}"
                        style="width: 100%">
                <el-table-column prop="code" label="Код причины">
                  <template slot-scope="props">
                    <el-select :disabled="!editFunctionCard" popper-class="search-select"
                               v-model="props.row.code" placeholder="Выберете код причины"
                               size="small">
                      <template v-for="group in staffCulpabilities">
                        <el-option-group
                          v-if="group.children.length > 0"
                          :key="group.id"
                          :label="group.name">
                          <el-option
                            v-for="item in group.children"
                            :key="item.id"
                            :label="item.code + ' (' + item.name + ')'"
                            :value="item.code">
                          </el-option>
                        </el-option-group>
                        <el-option
                          v-else
                          :key="group.id"
                          :label="group.code + ' (' + group.name + ')'"
                          :value="group.code">
                        </el-option>
                      </template>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="category" label="Категория персонала">
                  <template slot-scope="scope">
                    <el-select :disabled="!editFunctionCard" popper-class="search-select"
                               v-model="scope.row.category" placeholder="Выберете категорию"
                               size="small">
                      <el-option
                        v-for="item in staffCategories"
                        :key="item.id"
                        :label="item.code + ' (' + item.name + ')'"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="organization" label="Организация">
                  <template slot-scope="scope">
                    <remote-search-input
                      v-model="scope.row.organization"
                      :key="scope.row.key"
                      searchUrl="/api/organizations/SearchByName"
                      size="small"
                      return-prop="id"
                      placeholder="Выберите организацию"
                      :show-when-init="true"
                      :initial-value="scope.row.organizationName"
                      :start-length=1
                      :disabled="!editFunctionCard"
                      :allow-create="false"
                      @label-update="scope.row.organizationName = $event"
                    />
                  </template>
                </el-table-column>
                <el-table-column v-if="editFunctionCard" width="42px">
                  <template slot-scope="scope">
                    <el-button @click="deleteRow(scope.$index)" type="text"
                               icon="el-icon-delete"></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </el-form-item>
        </el-container>
      </el-form>
    </el-dialog>

    <second-equipments-tree-modal
      v-if="selectSecondEquipmentModalVisible"
      v-model="selectSecondEquipmentModalVisible"
      :data="this.functionCard.secondEquipmentsInit"
      :primary-equipment-id="eventCard.primaryEquipmentId"
      :second-equipment-panel-id="eventCard.secondEquipmentPanelId"
      :exact-energy-object-name="eventCard.energyObjectsName"
      :filter-is-active="true"
      :show-local-nsi-filter="false"
      :show-all-filter="true"
      :from-event-card="true"
      @on-update="confirmSelectedFunction"
    />

    <select-technical-reasons-modal
      :key="key"
      v-model="selectTechnicalReasonModalVisible"
      :tree="technicalReasons"
      :selected-items="functionCard.technicalReasons"
      @save="confirmSelectedTechnicalReasons($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';
import functionCardsApi from '@/api/eventsAndIncidents/functionCards/';
import udFunctionCardsApi from '@/api/eventsAndIncidents/userDataEvents/functionCards';
import secondEquipmentsApi from '@/api/nsi/secondEquipments/';
import SaveIcon from '@/assets/icons/save.svg';
import CancelIcon from '@/assets/icons/cancel.svg';
import formatters from '@/mixins/formatters.js';
import dateHelpers from '@/mixins/dateHelpers';
import secondEquipmentsTreeModal
  from '@/views/Global/SecondEquipmentsSelect/SecondEquimpentsTreeModal';
import SelectTechnicalReasonsModal
  from '@/views/EventsAndIncidents/Events/Modals/SelectTechnicalReasonsModal';

export default {
  name: 'EventFunctionCardModal',
  props: {
    id: { type: String, default: () => '' },
    value: { type: Boolean, default: () => false },
    eventCard: {
      type: Object, default: () => {}
    },
    functionCard: {
      type: Object, default: () => {}
    },
    editFunctionCard: { type: Boolean, default: () => false },
    addFunctionCard: { type: Boolean, default: () => false },
  },
  components: {
    SaveIcon,
    CancelIcon,
    secondEquipmentsTreeModal,
    RemoteSearchInput,
    SelectTechnicalReasonsModal
  },
  mixins: [formatters, dateHelpers],
  computed: {
    ...mapGetters('dictionaries', ['cardInformations', 'misoperationReasons', 'estimations', 'technicalReasons', 'staffCategories', 'staffCulpabilities']),
    ...mapGetters('identity', ['userHasRoleGroup']),
    visibleModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    eventDate: {
      get() {
        return this.dateGetter(this.eventCard.eventTime);
      },
      set(val) {
        this.eventCard.eventTime = this.dateSetter(val);
      }
    },
    allowStaffCategories() {
      return this.staffCategories.filter((x) => x.code === '00');
    },
    filteredEstimations() {
      return this.estimations.filter((x) => x.code === 'НЛ'
        || x.code === 'НО'
        || x.code === 'НИ'
        || x.code === 'НИ сигнал'
        || x.code === 'НЛ сигнал'
        || x.code === 'НО сигнал');
    }
  },
  created() {
    if (this.addFunctionCard !== true) {
      this.$nextTick(() => {
        this.handleEstimation(this.functionCard.estimationId);
      });
      this.functionCard.secondEquipmentCardFactoryNames = [];
      this.functionCard.secondEquipmentCardFactoryNames.push(this.functionCard.secondEquipmentCardFactoryName);
      this.functionCard.secondEquipmentsInit = [];
      this.functionCard.secondEquipmentsInit.push({
        id: this.functionCard.secondEquipmentId,
        name: this.functionCard.secondEquipmentName
      });
    }

    this.functionCard.organizationalReasons.forEach((f) => {
      f.key = this.newRandomKey();
    });
  },
  watch: {
    'functionCard.estimationId': function (newValue) {
      if (newValue) {
        this.handleEstimation(newValue);
      }
    }
  },
  data() {
    const estimationValidation = (rule, value, callback) => {
      const fEstimations = this.estimations.filter((x) => x.code === 'ПУ1'
        || x.code === 'ПУ2'
        || x.code === 'П'
        || x.code === 'ПУ'
        || x.code === 'ПН'
        || x.code === 'П сигнал');

      if (this.functionCard.estimationId === null) {
        return callback(new Error('Поле обязательно для заполнения'));
      } else if (fEstimations.some((x) => x.id === this.functionCard.estimationId)
        && (this.functionCard.technicalReasons.length > 0 || this.functionCard.organizationalReasons.length > 0 || this.functionCard.misoperationReasons.length > 0)) {
        return callback(new Error('Нельзя выбрать данную оценку, так как заполнены технические, организационные или детализированные причины неправильной работы'));
      } else {
        return callback();
      }
    };
    const secondEquipmentValidation = (rule, value, callback) => {
      if (this.functionCard.secondEquipmentsInit.length < 1) {
        return callback(new Error('Поле обязательно для заполнения'));
      } else {
        return callback();
      }
    };
    const orgReasonsValidation = (rule, value, callback) => {
      const reasons = this.functionCard.organizationalReasons;
      const duplicate = this.searchDuplicateOrgReasons(reasons);
      const missed = this.searchMissedFieldsOrgReasons(reasons);
      if (duplicate) {
        return callback(new Error('Указаны две или более одинаковые организационные причины'));
      }
      if (missed) {
        return callback(new Error('Все поля обязательны для заполнения'));
      }
      if (reasons.length === 0 && this.filteredEstimations.find((el) => this.functionCard.estimationId === el.id)) {
        this.$refs.functionCardForm.rules.organizationalReasons.required = true;
        return callback(new Error('Поле обязательно для заполнения'));
      }
      this.$refs.functionCardForm.rules.organizationalReasons.required = false;
      return callback();
    };
    return {
      key: 0,
      loading: false,
      selectSecondEquipmentModalVisible: false,
      selectTechnicalReasonModalVisible: false,
      organizationalReasonsRequired: false,
      selectTechnicalReasons: [],
      rules: {
        estimationId: [
          { validator: estimationValidation, trigger: 'blur', required: true }
        ],
        secondEquipmentName: [
          { validator: secondEquipmentValidation, trigger: 'blur', required: true }
        ],
        organizationalReasons:
          { validator: orgReasonsValidation, trigger: 'blur', required: false },
        technicalReasons:
          { trigger: 'blur', required: false, message: 'Поле обязательно для заполнения' },
      }
    };
  },
  methods: {
    handleEstimation(value) {
      if (this.filteredEstimations.find((el) => el.id === value)) {
        this.rules.technicalReasons.required = true;
        this.organizationalReasonsRequired = true;
      } else {
        this.rules.technicalReasons.required = false;
        this.organizationalReasonsRequired = false;
        this.$refs.functionCardForm.clearValidate();
      }
    },
    searchDuplicateOrgReasons(array) {
      // eslint-disable-next-line no-shadow
      return array.map((value) => value.code + value.category + value.organization).some((value, index, array) => array.indexOf(value) !== array.lastIndexOf(value));
    },
    searchMissedFieldsOrgReasons(array) {
      let result = false;
      // eslint-disable-next-line func-names
      // eslint-disable-next-line consistent-return
      array.forEach((x) => {
        if (this.allowStaffCategories.find((el) => el.id === x.category)) {
          if (x.code === null || x.code === '' || x.category === null || x.category === '') {
            result = true;
          }
        } else if (x.organization === null || x.organization === '' || x.code === null || x.code === '' || x.category === null || x.category === '') {
          result = true;
        }
      });
      return result;
    },
    confirmSelectedTechnicalReasons(event) {
      if (event) {
        this.functionCard.technicalReasons = event;
        this.selectTechnicalReasonModalVisible = false;
      }
    },
    async confirmSelectedFunction(secondEquipments) {
      if (secondEquipments) {
        this.functionCard.secondEquipmentsInit = [];
        this.functionCard.secondEquipmentCardFactoryNames = [];
        const factories = [];
        let counter = 0;
        secondEquipments.forEach(async (x) => {
          this.functionCard.secondEquipmentsInit.push({ id: x.baseId, name: x.name });
          const factoryRes = await secondEquipmentsApi.getSecondEquipmentFactoryName(x.baseId);
          factories.push(factoryRes.data);
          // ох уж этот foreach в js
          counter++;
          if (counter === secondEquipments.length) {
            this.functionCard.secondEquipmentCardFactoryNames = [...new Set(factories)];
          }
        });
      }
    },
    async showSelectFunctionModal() {
      this.selectSecondEquipmentModalVisible = true;
    },
    async showSelectTechnicalReasonModal() {
      this.selectTechnicalReasonModalVisible = true;
      this.key += 1;
    },
    async saveFunction() {
      const valid = await this.validateForm();
      if (!valid) return;

      this.loading = true;
      if (this.addFunctionCard) {
        const addAction = this.userHasRoleGroup('ExternalUser')
          ? udFunctionCardsApi.createFunctionCard
          : functionCardsApi.createFunctionCard;

        await addAction(this.functionCard).then(() => {
          this.visibleModal = false;
          this.$emit('function-card-added');
          this.$forceUpdate();
          this.loading = false;
        });
      } else {
        const updateAction = this.userHasRoleGroup('ExternalUser')
          ? udFunctionCardsApi.updateFunctionCard
          : functionCardsApi.updateFunctionCard;

        await updateAction(this.functionCard).then(() => {
          this.visibleModal = false;
          this.$parent.$forceUpdate();
          this.loading = false;
        });
      }
    },
    addNewRow() {
      this.functionCard.organizationalReasons.unshift({
        key: this.newRandomKey(),
        code: '',
        category: '',
        organization: ''
      });
    },
    deleteRow(idx) {
      this.$delete(this.functionCard.organizationalReasons, idx);
    },
    async validateForm() {
      const valid = await this.$refs.functionCardForm.validate();
      return valid;
    },
    distinctArray(arr) {
      const a = [];
      for (let i = 0, l = arr.length; i < l; i++) {
        if (a.indexOf(arr[i]) === -1 && arr[i] !== '') {
          a.push(arr[i]);
        }
      }
      return a;
    },
    newRandomKey() {
      return new Date().getTime() + Math.floor(Math.random() * 10000);
    }
  }
};
</script>
<style lang="scss">
@media screen and (max-width: 1800px) {
  .unique_line_height label {
    line-height: 20px;
    word-break: break-word;
  }
}

.event_function_rza {
  .el-form-item {
    margin-bottom: 1rem;
  }
}

.estimation {
  .el-form-item__error {
    width: 100%;
    position: inherit;
    top: 0;
  }
}

.estimation.is-error {
  .el-form-item__content {
    flex-direction: column;
  }
}

.event_function_rza-dialog .el-dialog__body {
  max-height: 65vh !important;
  overflow-y: auto !important;
}
</style>

<style scoped lang="scss">

.el-form {
  display: flex;
  align-content: space-between;
}

.el-container {
  display: inline-flex;
  flex-direction: column;
  width: 45%;
  height: auto;

  section:first-of-type {
    margin-right: 4%;
  }
}

.el-form-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0px;
}

.el-dialog__wrapper.el-dialog.el-dialog__body {
  padding: 20px;
}



.el-radio-group {
  font-size: initial !important;
}

.el-radio-group {
  width: 100%;
}

span.el-radio__label {
  padding-left: 5px;
}

span.el-radio__label > span {
  padding: 0px 5px;
}

span.el-radio__inner {
  margin: 0px 0px 9px 0px;
}

.el-tabs__header.is-top {
  margin: 0px !important;
}

button.el-button.el-button--text:first-child {
  margin-left: 0px;
}

.el-col > span {
  font-size: 12px;
}

.el-select {
  width: 100%;
}

span {
  //white-space: nowrap;
  vertical-align: middle;
}

.dialog-footer {
  text-align: center;
}

.scrollableTree {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 420px;
}

.tree-item > span > i {
  margin: 0px 5px 0px 0px;
}

.technical-reason--tree {
  font-size: 12px;

  .node__content {
    height: auto;
  }

  .custom-tree-node {
    .tree-item > span {
      white-space: normal;
    }
  }
}
</style>
