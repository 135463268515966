<template>
  <div>
    <el-row type="flex" justify="center" style="margin-top: 16px">
      <el-col :span="24" class="back_action_panel">
        <el-page-header @back="handleBack" content="">
        </el-page-header>
        <el-button @click="handleGetFile" type="text" icon="el-icon-download" style="padding-left: 0px;" :loading="fileLoading">
          Выгрузить
        </el-button>
        <el-divider direction="vertical"></el-divider>
        <div style="padding-left: 1rem; font-size: 18px;">{{report.name?  report.name : 'Отчет'}}</div>
      </el-col>
    </el-row>
    <el-row :gutter="20" type="flex" justify="center">
      <el-col :span="24" class="mt-2">
        <el-form label-width="400px" size="medium" label-position="left" class="report--form">
            <el-row :gutter="10" type="flex" style="margin-top:0px">
              <el-col :span="24" class="ml-2 filter--container custom-label-right" :xs="24" :sm="24" :md="16" :lg="14" :xl="12">
                <el-form-item v-for="item in showingFilterColumns" :label="item.filterDisplayName + ':'" :key="item.fullName">
                  <div>
                    <el-select v-model="item.filterIsNullValue">
                      <el-option label='Конкретное значение'
                                value=''>
                      </el-option>
                      <el-option label='Любое значение'
                                value='not null'>
                      </el-option>
                      <el-option label='Значение отсутствует'
                                value='null'>
                      </el-option>
                    </el-select>
                  </div>
                  <div v-if="!item.filterIsNullValue">
                    <div v-if="item.filterType=='in'">
                      <!--el-select v-model="item.filterMultiValue" multiple filterable size="medium" style="width:100%;">
                        <el-option v-for="catalogItem in getCatalogItems(item.fullName)"
                                  :key="catalogItem.id"
                                  :label="catalogItem.name"
                                  :value="catalogItem.id">
                        </el-option>
                      </el-select-->
                      <search-input
                        v-model="item.filterMultiValue"
                        multiple
                        :searchList=item.catalog
                        return-prop="id"
                        label="name"
                        size="medium"
                        :allow-create="false"
                        :show-when-init="true"
                      />
                    </div>
                    <div v-if="item.filterType=='like'"><el-input v-model="item.filterSingleValue"></el-input></div>
                    <div v-if="item.filterType=='equals'">
                      <el-date-picker v-if="item.dataType=='datetime'" v-model="item.filterSingleValue" format="dd.MM.yyyy" placeholder="дд.мм.гггг" v-mask="'##.##.####'" :picker-options="datePickerOptions"></el-date-picker>
                      <el-radio-group v-else-if="item.dataType=='boolean'" v-model="item.filterSingleValue">
                        <el-radio :label="null">Любой</el-radio>
                        <el-radio :label="'true'">Да</el-radio>
                        <el-radio :label="'false'">Нет</el-radio>
                      </el-radio-group>
                      <el-input-number v-else-if="item.dataType=='numeric'" v-model="item.filterSingleValue"></el-input-number>
                      <el-input v-else v-model="item.filterSingleValue"></el-input>
                    </div>
                    <div v-if="item.filterType=='between'">
                      <el-date-picker v-if="item.dataType=='datetime'" v-model="item.filterFromValue" format="dd.MM.yyyy" placeholder="дд.мм.гггг" v-mask="'##.##.####'" :picker-options="datePickerOptions"></el-date-picker>
                      <el-input-number v-else-if="item.dataType=='numeric'" v-model="item.filterFromValue"></el-input-number>
                      <el-input v-else v-model="item.filterFromValue"></el-input>
                      <span>&nbsp;-&nbsp;</span>
                      <el-date-picker v-if="item.dataType=='datetime'" v-model="item.filterToValue" format="dd.MM.yyyy" placeholder="дд.мм.гггг" v-mask="'##.##.####'" :picker-options="datePickerOptions"></el-date-picker>
                      <el-input-number v-else-if="item.dataType=='numeric'" v-model="item.filterToValue"></el-input-number>
                      <el-input v-else v-model="item.filterToValue"></el-input>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import moment from 'moment';
  import cloneDeep from 'lodash.clonedeep';
  import io from '@/utilities/fileIO';
  import reportsApi from '@/api/reports';
  import dateHelpers from '@/mixins/dateHelpers';
  import SearchInput from '@/components/Ui/SearchInput';

  export default {
    name: 'Report',
    props: ['reportId'],
    mixins: [dateHelpers],
    components: { SearchInput },
    data() {
      return {
        loading: false,
        fileLoading: false,

        report: {},

        filterCatalogs: [],
      };
    },
    async created() {
      this.loading = true;
      await this.getData();
      this.loading = false;
    },
    computed: {
      showingFilterColumns() {
        return this.getShowingFilterColumns();
      }
    },
    methods: {
      // eslint-disable-next-line no-unused-vars
      async getData() {
        const response = await reportsApi.getSinglePublished(this.reportId);
        this.report = response.data;

        const catalogsResponse = await reportsApi.getCatalogs(this.reportId);
        this.filterCatalogs = catalogsResponse.data.items;

        const showingFilterColumns = this.getShowingFilterColumns();
        for (let i = 0; i < showingFilterColumns.length; i++) {
          const filterColumn = showingFilterColumns[i];
          if (filterColumn.filterHasDefaultValue) {
            filterColumn.filterMultiValue = filterColumn.filterDefaultMultiValue;

            this.$set(filterColumn, 'filterSingleValue', filterColumn.filterDefaultSingleValue);
            this.$set(filterColumn, 'filterFromValue', filterColumn.filterDefaultFromValue);
            this.$set(filterColumn, 'filterToValue', filterColumn.filterDefaultToValue);
            this.$set(filterColumn, 'filterIsNullValue', filterColumn.filterDefaultIsNullValue);
            // console.log('filterC', filterColumn);
          }
        }

        this.filterCatalogs.forEach((x) => { 
          const filterColumn = showingFilterColumns.find((col) => col.fullName === x.columnFullName);
          if (!filterColumn) return;
          filterColumn.catalog = x.items; 
        });
      },
      async handleGetFile() {
        this.fileLoading = true;
        this.$message({ type: 'success', message: 'Формирование отчета запущено, пожалуйста, подождите' });

        const filterColumns = cloneDeep(this.getShowingFilterColumns());
        for (let i = 0; i < filterColumns.length; i++) {
          const filterColumn = filterColumns[i];
          if (filterColumn.dataType === 'datetime') {
            if (filterColumn.filterSingleValue) {
              filterColumn.filterSingleValueOriginal = moment(filterColumn.filterSingleValue).format('DD.MM.YYYY');
              filterColumn.filterSingleValue = this.dateSetter(filterColumn.filterSingleValue);
            }
            if (filterColumn.filterFromValue) {
              filterColumn.filterFromValueOriginal = moment(filterColumn.filterFromValue).format('DD.MM.YYYY');
              filterColumn.filterFromValue = this.dateSetter(filterColumn.filterFromValue);
            }
            if (filterColumn.filterToValue) {
              filterColumn.filterToValueOriginal = moment(filterColumn.filterToValue).format('DD.MM.YYYY');
              filterColumn.filterToValue = this.dateSetter(filterColumn.filterToValue);
            }
          }
        }
        const response = await reportsApi.downloadFile(this.reportId, filterColumns);

        this.fileLoading = false;

        if (response.data.exceptionDetails) {
          this.$alert(`При формировании отчета произошла ошибка: ${response.data.exceptionDetails}`, 'Ошибка');
        } else {
          io.downloadFile(response.data.content, response.data.contentType, response.data.fileName);
        }
      },
      handleBack() {
        this.$router.push({ name: 'Reports' });
      },

      getShowingFilterColumns() {
        if (this.report && this.report.meta && this.report.meta.columns) {
          return this.report.meta.columns.filter((x) => x.filter === true && x.filterShow === true).sort((a, b) => a.filterOrder - b.filterOrder);
        }
        return [];
      },

      getCatalogItems(columnFullName) {
        const catalogs = this.filterCatalogs.filter((x) => x.columnFullName === columnFullName);
        if (catalogs.length !== 0) {
          return catalogs[0].items;
        }
        return [];
      }
    },
  };
</script>

<style>
</style>
