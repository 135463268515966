<template>
  <el-menu class="app-sub-header"
           mode="horizontal"
           :default-active="activeLink"
           :router="false">
    <template v-if="routeIncludes('administration')">
      <el-submenu v-if="userHasRole('NsiImport')" index="nsi-import" popper-class="submenu-popper"
                  id='nsi-import' :class="{'is-active' : subActive('nsi-import')}">
        <template slot="title">
          Загрузка НСИ
        </template>
        <router-link class="el-menu-item submenu-item router-link-no-txt-decor"
                     to="/administration/aip-uploads">Загрузка АИП
        </router-link>
        <router-link class="el-menu-item submenu-item router-link-no-txt-decor"
                     to="/administration/analysis-mapping-uploads">Загрузка маппинга ПК Анализ
        </router-link>
        <router-link class="el-menu-item submenu-item router-link-no-txt-decor"
                     to="/administration/pk-analysis-uploads">Загрузка ПК Анализ
        </router-link>
        <router-link class="el-menu-item submenu-item router-link-no-txt-decor"
                     to="/administration/integrations">Журнал интеграций
        </router-link>
      </el-submenu>
      <el-submenu v-if="userHasRole('AccessRightsEdit,SystemSettingsEdit')" index="system-settings"
                  popper-class="submenu-popper" id="system-settings"
                  :class="{'is-active' : subActive('system-settings')}">
        <template slot="title">
          Системные настройки
        </template>
        <router-link class="el-menu-item submenu-item router-link-no-txt-decor"
                     v-if="userHasRole('AccessRightsEdit')" to="/administration/access-rights">
          Настройки прав доступа
        </router-link>
        <router-link class="el-menu-item submenu-item router-link-no-txt-decor"
                     v-if="userHasRole('AccessRightsEdit')" to="/administration/ad-groups">Группы AD
        </router-link>
        <router-link class="el-menu-item submenu-item router-link-no-txt-decor"
                     v-if="userHasRole('SystemSettingsEdit')" to="/administration/system-settings">
          Общие настройки
        </router-link>
        <router-link class="el-menu-item submenu-item router-link-no-txt-decor"
                     v-if="userHasRole('SystemSettingsEdit')"
                     to="/administration/administration-news">Редактор новостей
        </router-link>
        <router-link class="el-menu-item submenu-item router-link-no-txt-decor"
                     v-if="userHasRole('SystemSettingsEdit')"
                     to="/administration/administration-usefuldocuments">Эксплуатационная
          документация
        </router-link>
      </el-submenu>
      <el-submenu v-if="userHasRole('UsersEdit')" index="users-settings"
                  popper-class="submenu-popper" id="users-settings"
                  :class="{'is-active' : subActive('users-settings')}">
        <template slot="title">
          Пользователи
        </template>
        <router-link class="el-menu-item submenu-item router-link-no-txt-decor"
                     to="/administration/user-registrations">Регистрация пользователей
        </router-link>
        <router-link class="el-menu-item submenu-item router-link-no-txt-decor"
                     to="/administration/user-managements">Управление пользователями
        </router-link>
      </el-submenu>
      <router-link class="el-menu-item submenu-item router-link-no-txt-decor"
                   v-if="userHasRole('ReportsEdit')" to="/administration/reports"> Настройка отчетов
      </router-link>
      <el-submenu v-if="userHasRole('EventsView')" index="journals" popper-class="submenu-popper"
                  id="journals" :class="{'is-active' : subActive('journals')}">
        <template slot="title">
          Журналы
        </template>
        <router-link class="el-menu-item submenu-item router-link-no-txt-decor"
                     to="/administration/system-journal">Системный журнал
        </router-link>
        <router-link class="el-menu-item submenu-item router-link-no-txt-decor"
                     to="/administration/entity-journal">Журнал объектов
        </router-link>
        <router-link class="el-menu-item submenu-item router-link-no-txt-decor"
                     to="/administration/user-uploads"> Импорт XML-файла (События)
        </router-link>
      </el-submenu>
    </template>
    <template v-if="routeIncludes('events-incidents')">
      <router-link
        class="el-menu-item submenu-item router-link-no-txt-decor"
        v-if="userHasRole('IncidentsView,IncidentsEdit,IncidentsDoneView')
          && userHasRoleGroup('InternalUser')"
        to="/events-incidents/incidents">
        Аварии
      </router-link>
      <router-link class="el-menu-item submenu-item router-link-no-txt-decor"
                   v-if="userHasRole('IncidentsView,IncidentsEdit,IncidentsDoneView')"
                   to="/events-incidents/events"> События
      </router-link>
      <!-- <router-link class="el-menu-item submenu-item router-link-no-txt-decor" v-if="userHasRole('PkAnalysisXmlImport,EventsMatch')" to="/events-incidents/user-uploads"> Импорт XML-файла </router-link> -->
      <!--      <router-link class="el-menu-item submenu-item router-link-no-txt-decor" v-if="userHasRole('IncidentsView,IncidentsEdit,IncidentsDoneView')" to="/events-incidents/event_mappings">Сопоставление событий</router-link>-->
      <router-link class="el-menu-item submenu-item router-link-no-txt-decor"
                   v-if="showEventsMatch"
                   to="/events-incidents/event_mappings2">{{ eventCorName }}
      </router-link>
      
      <router-link class="el-menu-item submenu-item router-link-no-txt-decor" v-if="userHasRole('PkAnalysisXmlImport')"
                    to="/events-incidents/user-uploads"> Импорт XML-файла
      </router-link>
      <!-- <el-submenu index="journals" popper-class="submenu-popper" v-if="userHasRole('PkAnalysisXmlImport')" 
                  id="journals" :class="{'is-active' : subActive('journals')}">
        <template slot="title"> -->
        <el-button type="text" class="el-menu-item submenu-item" v-if="userHasRole('PkAnalysisXmlImport')">
          <a @click="handleUploadDialogOpen">Загрузить событие из XML</a>
        </el-button>
        <!-- </template>
      </el-submenu> -->
    </template>
    <upload-modal v-if="uploadDialogVisible"
                  v-model="uploadDialogVisible"
                  :file-text="fileText"
                  @start-upload="getData"/>
  </el-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import UploadModal from '@/views/EventsAndIncidents/UserUpload/UploadModal.vue';
import moment from 'moment/moment';
import EventCardCorrelationRepository from '@/repositories/EventCardCorrelationRepository';

export default {
  name: 'AppSubHeader',
  components: { UploadModal },
  data() {
    return {
      uploadDialogVisible: false,
      fileText: 'Выберите XML-файл формата ПК "Анализ 2009":',
      activeLink: null,
    };
  },
  mounted() {
    // const parentLink = this.$route.fullPath.split('/')[1];
    this.activeLink = this.$route.fullPath;
  },
  computed: {
    ...mapGetters('identity', ['userHasRole', 'userHasRoleGroup']),
    eventCorName() {
      return this.userHasRoleGroup('ExternalUser') ? 'Не сопоставленные события СО' : 'Не сопоставленные события субъектов';
    },
    showEventsMatch() {
      return this.userHasRole('EventsMatchExternal');
    }
  },
  methods: {
    routeIncludes(text) {
      if (!this.activeLink) return false;
      return this.activeLink.includes(text);
    },
    handleUploadDialogOpen() {
      this.uploadDialogVisible = true;
    },
    async getData() {
      this.loading = true;

      const params = {
        pageNumber: this.sortModel.pageNumber,
        pageSize: this.sortModel.pageSize,
        statuses: this.filterModel.statuses,
        eventNumber: this.filterModel.eventNumber,
        utcFromDt: this.filterModel.utcFromDt ? moment(this.filterModel.utcFromDt)
          .local()
          .format('YYYY-MM-DD HH:mm:ss') : null,
        utcToDt: this.filterModel.utcToDt ? moment(this.filterModel.utcToDt)
          .local()
          .format('YYYY-MM-DD HH:mm:ss') : null,
        sortField: this.sortModel.prop,
        sortDesc: this.sortModel.order === 'descending'
      };
      try {
        const res = await EventCardCorrelationRepository.getPagingAsync(params);
        this.events = res.items;
        this.totalCount = res.totalCount;
      } catch (e) {
        this.$message.error(e.message);
      } finally {
        this.loading = false;
      }
    },
    subActive(text) {
      if (!this.activeLink) return false;
      if (text === 'nsi-import') {
        if (this.activeLink.includes('aip-uploads') || this.activeLink.includes('analysis-mapping-uploads') || this.activeLink.includes('pk-analysis-uploads')) {
          return true;
        }
      }
      if (text === 'system-settings') {
        if (this.activeLink.includes('access-rights') || this.activeLink.includes('system-settings')) {
          return true;
        }
      }
      if (text === 'users-settings') {
        if (this.activeLink.includes('user-registration') || this.activeLink.includes('user-management')) {
          return true;
        }
      }
      if (text === 'journals') {
        if (this.activeLink.includes('system-journal') || this.activeLink.includes('entity-journal')) {
          return true;
        }
      }

      return false;
    },
  },
  watch: {
    $route(newVal) {
      this.activeLink = newVal.path;
    },
  },
};
</script>

<style scoped>
</style>
